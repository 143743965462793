
class ControlDropdown {
  constructor (ele, opts = {}) {
    const dflts = {
      placeholder: "Select"
    };
    this.conf = Object.assign(dflts, opts);
    this.control = ele;
    this.handleClick = this.handleClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.inputContainer = ele.querySelector("&>div>div.relative");
    this.inputContainer.addEventListener("click", this.handleClick);
    this.setSelected();
    this.control.dataset.init = "1";
  }

  setSelected () {
    const eles = this.inputContainer.querySelectorAll('input');
    let div = this.control.querySelector('[data-trigger]>div');
    let ary = [];
    eles.forEach((ele) => {
      if (ele.checked) {
        if (ele.value == "") {
          this.reset();
          return;
        }
        let lbl = ele.nextElementSibling.innerText.trim();
        ary.push(lbl);
        if (ele.type == "radio") {
          this.onBlur();
        }
      }
    });
    const str = (ary.length == 0) ? this.conf.placeholder : ary.join(", ");
    div.innerHTML = str;
    if (str != this.conf.placeholder) {
      this.control.classList.remove('placeholder');
    } else {
      this.control.classList.add('placeholder');
    }
  }

  reset () {
    const eles = this.inputContainer.querySelectorAll('input');
    let div = this.control.querySelector('[data-trigger]>div');
    eles.forEach((ele) => {
      ele.checked = false;
    });
    div.innerHTML = this.conf.placeholder;
    this.onBlur();
    this.control.classList.add('placeholder');
  }

  onBlur (e) {
    let parent = false;
    if (e) {
      parent = e.target.closest("div.form-field.dropdown");
    }
    if (!parent || (parent != this.control)) {
      this.control.classList.remove('focus');
      document.removeEventListener('click', this.onBlur);
    }
  }

  handleClick (e) {
    let area = e.target.closest("[data-trigger]");
    if (area) {
      let method = (this.control.classList.contains('focus')) ? "remove" : "add";
      this.control.classList[method]('focus');
      document.addEventListener('click', this.onBlur);
      return;
    }
    area = e.target.closest("[data-option]");
    if (area) {
      this.setSelected();
      return;
    }
  }
}

function initNewDropdowns () {
  const eles = document.querySelectorAll(".form-field.dropdown:not([data-init])");
  eles.forEach((ele) => {
    new ControlDropdown(ele, ele.dataset);
  });
}

function initDropdowns () {
  document.addEventListener('DOMContentLoaded', () => {
    initNewDropdowns();
  });
}

export {ControlDropdown, initDropdowns, initNewDropdowns};
