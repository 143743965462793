import Testimonial from "./testimonial"

export const initTestimonials = function() {
  document.addEventListener('DOMContentLoaded', () => {

    const testimonialBlocks = [...document.querySelectorAll('.testimonials')]

    if (testimonialBlocks.length === 0) {
      return;
    }

    testimonialBlocks.forEach((testimonialBlock) => {

      const contentBlocks = [...testimonialBlock.querySelectorAll('[data-content]')];
      const mediaBlocks = [...testimonialBlock.querySelectorAll('[data-media]')];

      let testimonials = [];
      let currentIndex = 0;
      let currentActive = null;

      contentBlocks.forEach((contentBlock, index) => {
        const testimonial = new Testimonial(testimonialBlock, contentBlock, mediaBlocks[index])
        testimonial.init()

        testimonials.push(testimonial)

        // Set the first region in the list to active
        if (index == 0) {
          testimonial.show();
          currentActive = testimonial;
          currentIndex = 0;
        }
      })


      function updateActiveTestimonial(index) {
        if (currentActive) {
          currentActive.hide()
        }

        testimonials[index].show(250)
        currentActive = testimonials[index];
        currentIndex = index
      }

      const nextBtn = testimonialBlock.querySelector('[data-testimonial-next]');
      const prevBtn = testimonialBlock.querySelector('[data-testimonial-prev]');

      if (nextBtn) {
        nextBtn.addEventListener('click', () => {
          let nextIndex = currentIndex + 1;
          if (nextIndex >= testimonials.length) {
            nextIndex = 0;
          }

          updateActiveTestimonial(nextIndex)
        })

        prevBtn.addEventListener('click', () => {
          let prevIndex = currentIndex - 1;
          if (prevIndex < 0) {
            prevIndex = testimonials.length - 1;
          }

          updateActiveTestimonial(prevIndex)
        })
      }
    })
  })
}
