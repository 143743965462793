import 'lazysizes'

import legacyComponents from './legacy/components';

import Form from './components/form';
import Input from './components/input';
import InstallationTypes from './components/installation-types';
import NewsletterConnect from './components/newsletter-connect';
import FeaturedRooms from './components/featured-rooms';
import FeaturedProducts from './components/popular-products';
import CalendlyScheduler from './components/calendly-scheduler';
import ProductFeaturesMap from './components/product-features-map';
import FullAutoplayCarousel from './components/full-autoplay-carousel';
import ImageComparison from './components/image-comparison';
import ProductDetails from './components/product-details';
import Accordion from './components/accordion/accordion';
import ProductRegistrationForm from './components/product-registration-form';
import InstallerAdminInstallers from './components/installer-admin/installers';
import InstallerAdminProjectsForm from './components/installer-admin/projects-form'
import InstallerAdminProductsForm from './components/installer-admin/products-form'
import InstallerAdminStatsChart from './components/installer-admin/stats-chart';

const siteComponents = {
  form: Form,
  input: Input,
  'installation-types': InstallationTypes,
  'newsletter-connect': NewsletterConnect,
  'featured-rooms': FeaturedRooms,
  'featured-products': FeaturedProducts,
  'calendly-scheduler': CalendlyScheduler,
  'product-features-map': ProductFeaturesMap,
  'full-autoplay-carousel': FullAutoplayCarousel,
  'image-comparison': ImageComparison,
  'product-details': ProductDetails,
  'accordion': Accordion,
  'product-registration-form': ProductRegistrationForm,
  'installer-admin-projects-form': InstallerAdminProjectsForm,
  'installer-admin-products-form': InstallerAdminProductsForm,
  'installer-admin-stats-chart': InstallerAdminStatsChart,
  'installer-admin-installers': InstallerAdminInstallers,
}

import PageComponents from './components/page-components';

// Compbine legacy and new site components
const componentClassMap = {
  ...legacyComponents,
  ...siteComponents
}

// Define all actions/commands that components pub/sub
const actions = {
  loadModal: 'LOAD_MODAL',
  openModal: 'OPEN_MODAL',
  closeModal: 'CLOSE_MODAL',
  lockScroll: 'LOCK_SCROLL',
  unlockScroll: 'UNLOCK_SCROLL',
  setInputValue: 'SET_INPUT_VALUE',
};

const pageComponents = new PageComponents({
  componentClassMap,
  actions
})

import initNavigation from './components/navigation';
import initHeroIntro from './components/hero-intro';
import initOffsetGrid from './components/offset-grid';
import initMediaCopyGrid from './components/media-copy-grid';
import initMediaVideos from './components/media-videos';
import initFeaturedProject from './components/featured-project';
import initTransformationsCarousel from './components/transformations-carousel';
import { initProjectRegions } from './components/project-regions';
import initCalculatorFeature from './components/calculator-feature';
import initMediaReveal from './components/media-reveal';
import initHeroDrawer from './components/hero-drawer';
import initHeroParallax from './components/hero-parallax';
import initSiteMotion from './components/site-motion';
import { initModals ,initModalTriggers } from './components/modal';
import initBlogCardGrid from './components/blog-card-grid';
import initCategoryGrid from './components/category-grid';
import initProductListGrid from './components/product-list-grid';
import initVisualizerCarousel from './components/visualizer-app-carousel';
import { initColumnCountToggle } from './components/col-count-toggle';
import { initTabs } from './components/tabs';
import { initTransformations } from './components/transformation';
import { initOptionToggles } from './components/option-toggle';
import { initTestimonials } from './components/testimonials';
import initRoomTransformCarousel from './components/room-transform-carousel';
import initDesignAdviceCarousel from './components/design-advice-carousel';
import initImageZoom from './components/image-zoom';
import initAnchorScroll from './components/anchor-scroll';
import initProjectQuoteCarousel from './components/project-quote-carousel';
import initMouseoverCaption from './components/mouseover-caption';
import initButtonToggle from './components/button-toggle';
import { initDropdowns } from './forms/control-dropdown';
import initPromoPopup from './components/promo';
import classMap from './legacy/components';
import productFeaturesMap from './components/product-features-map';

import initAnalytics from './libs/analytics'

const navigation = initNavigation()

initHeroIntro()
initPromoPopup()
initOffsetGrid()
initMediaCopyGrid()
initMediaVideos()
initFeaturedProject()
initTransformationsCarousel()
initProjectRegions()
initCalculatorFeature()
initMediaReveal()
initHeroDrawer()
initHeroParallax()
initSiteMotion()
initColumnCountToggle();
initTabs();
initTransformations();
initOptionToggles();
initTestimonials();
initBlogCardGrid();
initCategoryGrid();
initProductListGrid();
initModals(navigation, pageComponents);
initModalTriggers();
initVisualizerCarousel();
initRoomTransformCarousel();
initDesignAdviceCarousel();
initImageZoom();
initAnchorScroll();
initProjectQuoteCarousel();
initMouseoverCaption();
initButtonToggle();
initDropdowns();

initAnalytics();


window.addEventListener('load', () => {
  if (typeof window.HubSpotConversations !== 'undefined') {

    window.HubSpotConversations.on('userInteractedWithWidget', payload => {
      window.analytics.trackEvent({
        eventCategory: 'LiveChat_Interaction',
        eventAction: 'Interaction',
        eventLabel: 'LiveChat',
      })
    });

    window.HubSpotConversations.on('userInteractedWithWidget', payload => {
      window.analytics.trackEvent({
        eventCategory: 'LiveChat_Started_Conversation',
        eventAction: 'Started_Conversation',
        eventLabel: 'LiveChat',
      })
    });
  }
})

document.querySelectorAll('[data-behavior-livechat]').forEach((liveChatBtn) => {

  liveChatBtn.addEventListener('click', (event) => {
    event.preventDefault();

    if (typeof window.HubSpotConversations !== 'undefined') {
      window.HubSpotConversations.widget.open();

      window.analytics.trackEvent({
        eventCategory: 'LiveChat_Click_Button',
        eventAction: 'Click',
        eventLabel: 'LiveChat',
      })

    }
  })
})

window.addEventListener('load', () => {
  let modalId = location.hash.substring(1);
  if (typeof window.modals !== 'undefined' && window.modals[modalId]) {
    window.modals[modalId].init(window)
    window.modals[modalId].open()
  }
})

document.addEventListener('DOMContentLoaded', () => {
  pageComponents.build()
});

