import TransitionModal from './transition';

class VideoModal extends TransitionModal {

  constructor(modal) {
    super(modal)
    this.videoContainer = this.modal.querySelector('[data-full-video]')
  }

  open() {
    return super.open().then(() => {
      return this.setupVideo()
    })
  }

  cleanVideoUrl(videoUrl) {
    if (!videoUrl.includes('player.vimeo.com')) {
      const videoMatches = videoUrl.match(/https?\:\/\/(www\.)?vimeo.com\/(\d+)/)
      if (videoMatches && typeof videoMatches[2] !== 'undefined') {
        videoUrl = `https://player.vimeo.com/video/${videoMatches[2]}?quality=720p`
      }
    }

    return videoUrl;
  }

  setupVideo() {

    dataLayer.push({ // eslint-disable-line no-undef
        "event": "videoPlayer",
        "eventCategory": "Video",
        "eventAction": "Click to Play",
        "eventLabel": this.trigger.dataset.fullVideo,
    });

    let fullVideoUrl = this.cleanVideoUrl(this.trigger.dataset.fullVideo);
    this.videoContainer.innerHTML = `<iframe src="${ fullVideoUrl }" class="w-full h-full object-cover"></iframe>`
    return Promise.resolve()
  }
}

export default VideoModal;
