export default class {

  constructor() {
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)

    window.dataLayer = window.dataLayer || []
  }

  get dataLayer() {
    return window.dataLayer
  }

  init() {
    this.updateHandlers();
  }

  buildEvent(eventDetails) {
    let defaultEventType = 'GAEvent';

    // Ensure there is an event type set
    eventDetails.eventType = eventDetails.eventType || defaultEventType;

    let { eventType, ...eventProperties } = eventDetails

    return { event: eventType, ...eventProperties }
  }

  trackEvent(eventType, properties) {

    let event = {}
    if (typeof properties === 'undefined') {
      // First argument is an event object
      properties = eventType
      eventType = properties.eventType
    }

    event = this.buildEvent({ eventType, ...properties})

    this.dataLayer.push(event)
    console.log('dataLayer.push', event)
  }

  updateClicks() {
    ;[...document.querySelectorAll('[data-track-click]')].forEach((btn) => {
      btn.removeEventListener('click', this.handleClick)
      btn.addEventListener('click', this.handleClick)
    })
  }

  updateForms() {
    ;[...document.querySelectorAll('[data-track-submit]')].forEach((form) => {
      form.removeEventListener('submit', this.handleFormSubmit)
      form.addEventListener('submit', this.handleFormSubmit)
    })
  }

  updateHandlers() {
    this.updateClicks();
    this.updateForms();
  }

  handleClick(event) {
    try {

      const elem = event.target.matches('[data-track-click]') && event.target || event.target.closest('[data-track-click]')
      if (elem.dataset.trackClick) {
        const { eventType, ...eventParams } = this.buildEvent(JSON.parse(elem.dataset.trackClick || '{}'))
        this.trackEvent(eventType, eventParams)
        return;
      }
    } catch(e) {
      console.warn(e)
    }
  }

  handleFormSubmit(event) {
    try {
      const { eventType, ...eventParams } = this.buildEvent(JSON.parse(event.target.dataset.trackSubmit || '{}'))
      this.trackEvent(eventType, eventParams)
    } catch(e) {
      console.warn(e)
    }
  }
}
