import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function() {

  document.addEventListener('DOMContentLoaded', () => {

    const mediaReveal = document.querySelector('[data-media-reveal]')

    if (!mediaReveal) {
      return;
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: mediaReveal.querySelector('.media-mask'),
        start: "top bottom",
        end: "bottom center",
        scrub: 1,
        toggleActions: "play complete reverse reverse"
      }
    })

    tl.add(
      gsap.to('.media-reveal', {
        '--media-reveal-background-top': 0,
        '--media-reveal-background-right': 0,
        '--media-reveal-background-bottom': 0,
        '--media-reveal-background-left': 0,
        duration: 2,
        ease:"power2.out"
      }),
      'start'
    )

    tl.add(
      gsap.to('.media-reveal .media-mask', {
        '--media-reveal-top': 0,
        '--media-reveal-right': 0,
        '--media-reveal-bottom': 0,
        '--media-reveal-left': 0,
        duration: 3,
        ease:"power2.out"
      }),
      'start'
    )

    tl.add(
      gsap.to('.media-reveal__header', {
        y: '20%',
        duration: 3,
        // ease:"power.out"
      }),
      'start'
    )
  })
}
