export default function() {

  const videos = document.querySelectorAll('.media video')

  if (!videos) {
    return
  }

  function inViewport(entries) {
    const entry = entries[0]
    if (entry) {
      const video = entry.target
      if (entry.isIntersecting) {
        video.play()
      } else {
        video.pause()
      }
    }
  }


  videos.forEach((element) => {

    // NOTE: we skip any videos within our heroes because position:fixed doesn't play
    // particularly well with IntersectionObserver.
    if (element.closest('.hero-media')) {
      return
    }

    const inViewportObserver = new IntersectionObserver(
      inViewport,
      {
        root: null,
        threshold: 0.0
      }
    )
    inViewportObserver.observe(element)
  })

}
