import Modal from "./modal";

class TransitionModal extends Modal {
  constructor(modal) {
    super(modal)

    this.handleCloseTransition = this.handleCloseTransition.bind(this)
  }

  close() {
    this.modal.classList.remove('reveal')
    this.modal.addEventListener('transitionend', this.handleCloseTransition)

    return new Promise((resolve) => {
      this.resolveClose = resolve
    })
  }

  open() {
    return super.open().then(() => {
      this.modal.classList.add('reveal')
    })
  }

  handleCloseTransition(event) {
    if (event.target == this.modal) {

      if (this.resolveClose) {
        this.resolveClose()
      }

      this.modal.removeEventListener('transitionend', this.handleCloseTransition)
      super.close()
    }
  }
}

export default TransitionModal;
