import Swiper from "swiper";

export default class {
    constructor({
        id,
        prevId,
        nextId,
        counterHandle,
    }) {
        const el = document.getElementById(id);
        const swiperContainer = el.querySelector('.swiper-container');
        const controlsPrev = document.getElementById(prevId);
        const controlsNext = document.getElementById(nextId);
        const counters = el.querySelectorAll(counterHandle);

        const swiper = new Swiper(swiperContainer, {
            init: false,
            autoplay: {
                delay: 5000, // sync with kebab-counters.scss
                disableOnInteraction: false,
            },
            slidesPerView: 1,
            navigation: {
                prevEl: controlsPrev,
                nextEl: controlsNext,
            },
        });

        function handleChange() {
            counters.forEach((o) => {
                o.classList.remove('is-active');
            });
            counters[swiper.activeIndex].classList.add('is-active');
        }

        // Initialize Swiper
        swiper.on('init', function () {
            handleChange();
        });
        swiper.on('slideChange', function () {
            handleChange();
        });

        swiper.init();
    }
}
