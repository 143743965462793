import WidgetSwitch from './widget-switch'

class Transformation {

    constructor (el) {
      this.el = el

      this.switch = new WidgetSwitch(
        this.el.querySelector('.widget-switch')
      )

      this.el.addEventListener('click', this.handleClick.bind(this))
      this.set()
    }

    handleClick (e) {
      e.preventDefault()
      if (this.el.classList.contains('after')) {
        this.set('before')
      } else {
        this.set('after')
      }
    }

    set (state) {
      if (!state) {
        state = this.el.classList.contains('after') ? 'after' : 'before'
      }
      if (state == 'before') {
        this.el.classList.remove('after')
        this.switch.before()
      } else {
        this.el.classList.add('after')
        this.switch.after()
      }
    }
}

function initTransformations () {

  const init = () => {
    const els = document.querySelectorAll('.transformation')
    if (els) {
      els.forEach((el) => {
        if (typeof el.transformation !== 'undefined') {
          return;
        }

        el.transformation = new Transformation(el)
      })
    }
  }

  if (document.readyState === 'complete') {
    init();
  }

  document.addEventListener('DOMContentLoaded', () => {
    init()
  })
}

export {Transformation, initTransformations}
