import Swiper from "swiper";
import swiperSettings from './swiper-settings';

export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    let swiperContainer = document.querySelector('.room-transform-swiper')

    if(!swiperContainer){
      return;
    }

    function doSwiper() {
      const swiper = new Swiper(swiperContainer.querySelector('.swiper'), {
        ...swiperSettings
      });

      const leftBtn = swiperContainer.querySelector('.swiper-button-prev')
      const rightBtn = swiperContainer.querySelector('.swiper-button-next')

      leftBtn.addEventListener('click', () => {
        swiper.slidePrev()
      })

      rightBtn.addEventListener('click', () => {
        swiper.slideNext()
      })

      swiper.init();
    }

    doSwiper();
  })
}
