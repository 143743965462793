class SubNavigation {
  constructor(navigation) {
    this.navigation = navigation;

    this.tabbedNavigationItems = this.navigation.navigationElem.querySelectorAll('.navigation-panels__panel-nav a[aria-controls]')
    this.featuresSubPanel = this.navigation.navigationElem.querySelector('.navigation-panel__panel-sub-features')
    this.featureBackButtons = this.navigation.navigationElem.querySelectorAll('.navigation-panel__panel-feature-back')

    this.featurePanels = []
    if (this.featuresSubPanel) {
      this.featurePanels = [...this.featuresSubPanel.querySelectorAll('.navigation-panel__panel-feature')]
    }

    this._isMobileNav = false;

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleFeatureMouseEnter = this.handleFeatureMouseEnter.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleFeatureBackClick = this.handleFeatureBackClick.bind(this)

    this.mouseEnterTimeout = null;
  }

  set isMobileNav(val) {
    this._isMobileNav = val
  }

  get isMobileNav() {
    return this._isMobileNav;
  }

  handleClick(event) {
    const target = event.target.closest('[aria-controls]') || event.currentTarget
    if (this.isMobileNav) {
      const featureId = target.getAttribute('aria-controls')

      if (featureId) {
        event.preventDefault()
      }

      this.updateActiveFeatures(target)
      return;
    }

    this.navigation.close()
  }

  handleFeatureBackClick() {
    document.body.classList.toggle('subnavigation-open')
  }

  handleMouseEnter(event) {
    const target = event.target.closest('[aria-controls]') || event.currentTarget
    clearTimeout(this.mouseEnterTimeout)
    this.mouseEnterTimeout = setTimeout(() => {
      this.updateActiveFeatures(target)
    }, 250)
  }

  updateActiveFeatures(target) {
    const featureId = target.getAttribute('aria-controls')

    this.tabbedNavigationItems.forEach((navItem) => {
      navItem.setAttribute('aria-selected', target === navItem ? 'true' : 'false')
    })

    let hasSubPanel = false;
    this.featurePanels.forEach((featurePanel) => {
      if (featurePanel.id === featureId) {
        hasSubPanel = true;
        featurePanel.classList.add('selected')
        return;
      }

      featurePanel.classList.remove('selected')
    })

    document.body.classList.toggle('subnavigation-open', hasSubPanel)
  }

  handleFeatureMouseEnter(event) {
    // Clear timeouts so user has time to interacte with the feature panel
    clearTimeout(this.mouseLeaveTimeout)
    clearTimeout(this.mouseEnterTimeout)
  }

  reset() {
    document.body.classList.remove('subnavigation-open')
  }

  updateEventListeners() {
    this.tabbedNavigationItems.forEach((navItem) => {
      navItem.removeEventListener('mouseenter', this.handleMouseEnter)
      navItem.removeEventListener('click', this.handleClick)
    })

    this.featurePanels.forEach((featurePanel) => {
      featurePanel.removeEventListener('mouseenter', this.handleFeatureMouseEnter)
    })

    this.featureBackButtons.forEach((navItem) => {
      navItem.removeEventListener('click', this.handleFeatureBackClick)
    })

    this.tabbedNavigationItems.forEach((navItem) => {
      navItem.addEventListener('click', this.handleClick)
    })

    if (this.isMobileNav) {
      this.featureBackButtons.forEach((navItem) => {
        navItem.addEventListener('click', this.handleFeatureBackClick)
      })

      return;
    }

    this.tabbedNavigationItems.forEach((navItem) => {
      navItem.addEventListener('mouseenter', this.handleMouseEnter)
    })

    this.featurePanels.forEach((featurePanel) => {
      featurePanel.addEventListener('mouseenter', this.handleFeatureMouseEnter)
    })
  }
}

export default SubNavigation;
