import Modal from './modal';
import ProjectCalculatorModal from './project-calculator/calculator';
import ProjectCalculatorSendModal from './project-calculator/send';
import ProjectCalculatorLeads from './project-calculator/leads';
import TrayModal from "./tray";
import VideoModal from "./video";
import ProductReviewsModal from './product-reviews'

const modalTypes = {
  'project-calculator': ProjectCalculatorModal,
  'project-calculator-disclaimer': Modal,
  'project-calculator-send': ProjectCalculatorSendModal,
  'project-calculator-leads': ProjectCalculatorLeads,
  'tray': TrayModal,
  'video': VideoModal,
  'product-reviews': ProductReviewsModal
}


export const initModals = (navigation, pageComponents) => {

  let modals = window.modals || [];
  let pageModals = [...document.querySelectorAll('[data-modal]')];

  // Setup modal instances
  pageModals.forEach((pageModal) => {

    const modalType = pageModal.dataset.modal;

    if (typeof modalTypes[modalType] === 'undefined') {
      console.warn(`Modal type not found ${modalType}`)
      return;
    }

    modals[modalType] = new modalTypes[modalType](pageModal);
    modals[modalType].setNavigation(navigation)
    modals[modalType].setPageComponents(pageComponents)
  })

  window.modals = modals;
}

if (typeof window.handleModalTriggerClick !== 'function') {
  window.handleModalTriggerClick = function(event) {

    const trigger = event.target.matches(['data-modal-trigger']) && event.target || event.target.closest('[data-modal-trigger]')

    if (!trigger) {
      return;
    }

    const modalType = trigger.dataset.modalTrigger;
    if (typeof modals[modalType] == 'undefined') {
      console.warn(`Modal type not found ${modalType} for trigger: ${trigger}`)
      return;
    }

    event.preventDefault()

    const modal = modals[modalType];
    modal.init(trigger).then(() => modal.open())
  }
}

export const initModalTriggers = () => {
  window.modalTriggers = window.modalTriggers || [];

  // Remove event handlers for existing triggers
  window.modalTriggers.forEach((modalTrigger) => {
    modalTrigger.removeEventListener('click', window.handleModalTriggerClick)
  })

  window.modalTriggers = [...document.querySelectorAll('[data-modal-trigger]')];
  window.modalTriggers.forEach((modalTrigger) => {
    modalTrigger.addEventListener('click', window.handleModalTriggerClick)
  })

  window.modalTriggers = modalTriggers;
}

