import TransitionModal from "./transition";

class ProductReviewsModal extends TransitionModal {

  constructor(modal) {
    super(modal)
    this.modalReviewsElem = this.modal.querySelector('[data-modal-reviews]')
  }

  close() {
    super.close().then(() => {
      if (this.reviewsElem) {
        this.modalReviewsElem.removeChild(this.reviewsElem)
        this.reviewsElem = null;
      }
    })
  }

  open() {
    return super.open().then(() => this.updateContent())
  }

  updateContent() {

    this.heading.innerHTML = this.trigger.dataset.installerName
    try {
      this.modal.querySelector('[data-modal-product-name]').innerHTML = JSON.parse(this.trigger.dataset.productName)
    } catch(e) {
      console.warn(e)
    }

    let existingReviewsElem = this.modalReviewsElem.querySelector('[data-bv-show]')
    if (existingReviewsElem) {
      existingReviewsElem.parentNode.removeChild(existingReviewsElem)
      existingReviewsElem = null;
    }

    const bazaarvoiceId = this.trigger.dataset.productBazaarvoiceId
    const reviewsElem = document.createElement('div')
    reviewsElem.setAttribute('data-bv-show', 'reviews')
    reviewsElem.setAttribute('data-bv-product-id', bazaarvoiceId)
    reviewsElem.setAttribute('class', 'max-w-[95%]')

    this.modalReviewsElem.appendChild(reviewsElem)

    return Promise.resolve()
  }
}


export default ProductReviewsModal;
