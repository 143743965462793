class ModalStack {
  constructor() {
    this.stack = [];

    this.handleKeyDown = this.handleKeyDown.bind(this)
    document.documentElement.addEventListener('keydown', this.handleKeyDown)
  }

  push(modal) {
    this.stack.push(modal)
  }

  pop() {
    return this.stack.pop()
  }

  remove(modal) {
    const modalIndex = this.stack.findIndex((stackModal) => modal === stackModal)
    if (modalIndex > -1) {
      this.stack = this.stack.slice(modalIndex, 1)
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Escape' && this.stack.length) {
      const modalToClose = this.pop()

      if (modalToClose) {
        modalToClose.close()
      }
    }
  }
}

export default ModalStack
