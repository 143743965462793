import SubNavigation from "./sub-navigation";
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';

class Navigation {

  constructor() {
    this.burger = document.querySelector('.burger');
    this.navigationElem = document.querySelector('.navigation');

    if (!this.navigationElem) {
      return;
    }

    this.navigationElemPanels = document.querySelector('.navigation-panels');
    this.mobileNavigationPanel = document.querySelector('.navigation__mobile-panel')
    this.mobileBackButtons = this.navigationElem.querySelectorAll('.navigation-panels__panel-back')

    this.navItems = [...this.navigationElem.querySelectorAll('.navigation-bar .nav-item')]

    this.mobileNavItems = this.mobileNavigationPanel.querySelectorAll('a[aria-controls]')

    this.handleBurgerClick = this.handleBurgerClick.bind(this)

    this.handleNavItemMouseEnter = this.handleNavItemMouseEnter.bind(this)
    this.handleNavItemMouseLeave = this.handleNavItemMouseLeave.bind(this)
    this.handlePanelsMouseLeave = this.handlePanelsMouseLeave.bind(this)
    this.handlePanelsMouseEnter = this.handlePanelsMouseEnter.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleNavItemClick = this.handleNavItemClick.bind(this)
    this.handlePanelTransitionOpen = this.handlePanelTransitionOpen.bind(this)
    this.handlePanelTransitionClose = this.handlePanelTransitionClose.bind(this)
    this.handleMobileTransitionOpen = this.handleMobileTransitionOpen.bind(this)
    this.handleMobileTransitionClose = this.handleMobileTransitionClose.bind(this)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)

    this.subNavigation = new SubNavigation(this);
    this.desktopMq = window.matchMedia('(min-width: 980px)');

    this.panelHeight = 0;
    this.isHeightIncrease = false;

    this.interactionTimeout = 250;
  }

  /**
   * Handle mobile burger nav clicks. Revealing the mobile nav taks place as a step
   * before the navs open/close state so it uses mobile specific classes.
   * @returns void
   */
  handleBurgerClick() {
    if (document.body.classList.contains('navigation-mobile-open')) {

      setTimeout(() => {
        this.mobileNavigationPanel.addEventListener('transitionend', this.handleMobileTransitionClose)
        document.body.classList.remove('navigation-mobile-open')
      }, this.interactionTimeout)
      return;
    }

    this.mobileNavigationPanel.addEventListener('transitionend', this.handleMobileTransitionOpen)
    document.body.classList.add('navigation-mobile-open')
    lock(this.navigationElemPanels, { useGlobalLockState: true })
  }

  handleMobileTransitionOpen(event) {
    if (!event.target.classList.contains('navigation__mobile-panel')) {
      return;
    }

    this.mobileNavigationPanel.removeEventListener('transitionend', this.handleMobileTransitionOpen)
  }

  handleMobileTransitionClose(event) {
    if (!event.target.classList.contains('navigation__mobile-panel')) {
      return;
    }

    this.mobileNavigationPanel.removeEventListener('transitionend', this.handleMobileTransitionClose)
    this.reset();
  }

  handleNavItemClick(event) {

    event.preventDefault()

    document.body.classList.add('navigation-ready')

    const navItem = event.currentTarget.closest('[aria-controls]') || event.currentTarget;
    const navPanelId = navItem.getAttribute('aria-controls')

    this.open(navPanelId)
  }

  handleBackButtonClick() {
    this.close()
  }

  handleNavItemMouseEnter(event) {

    clearTimeout(this.panelTimeout)
    clearTimeout(this.navigationElemLeaveTimeout);
    clearTimeout(this.navigationElemEnterTimeout);

    const navItem = event.currentTarget.closest('[aria-controls]') || event.currentTarget;
    const navPanelId = navItem.getAttribute('aria-controls')

    this.navigationElemEnterTimeout = setTimeout(() => {
      document.body.classList.add('navigation-ready')

      this.updateOverState(navItem)
      this.open(navPanelId)
    }, this.interactionTimeout)
  }

  handleNavItemMouseLeave(event) {
    clearTimeout(this.navigationElemEnterTimeout);
    this.panelTimeout = setTimeout(() => {
      this.close()
    }, this.interactionTimeout)

    this.navigationElemLeaveTimeout = setTimeout(() => {
      this.updateOverState();
    }, this.interactionTimeout)
  }

  updateOverState(navItem) {

    if (!navItem) {
      document.body.classList.remove('navigation-over')
      let overItem = this.navItems.find((item) => item.classList.contains('over'))
      if (overItem) {
        overItem.classList.remove('over');
      }
      return;
    }

    document.body.classList.add('navigation-over')

    this.navItems.forEach((item) => {
      if (navItem == item) {
        navItem.classList.add('over')
        return;
      }

      item.classList.remove('over')
    })
  }


  handlePanelsMouseLeave(event) {
    this.panelTimeout = setTimeout(() => {
      this.close()
    }, this.interactionTimeout)

    this.navigationElemLeaveTimeout = setTimeout(() => {
      this.updateOverState();
    }, this.interactionTimeout)
  }

  handlePanelsMouseEnter(event) {
    clearTimeout(this.panelTimeout);
    clearTimeout(this.navigationElemLeaveTimeout)
  }

  isOpen() {
    return document.body.classList.contains('navigation-open')
  }

  open(navPanelId) {

    const navPanel = document.getElementById(navPanelId)

    // If the current nav item doesn't have a related navigation panel
    // The current panel can be closed immediately.
    if (!navPanel) {
      if (this.isOpen()) {
        this.close()
      }

      return;
    }

    // On resize, the nav is set display none to prevent odd jumps.
    // Reset the display styles once the nav is re-opened.
    this.navigationElemPanels.style.display = ''

    // // Hide the existing item
    // const selectedPanel = this.navigationElemPanels.querySelector('.selected')
    // if (selectedPanel) {
    //   selectedPanel.classList.remove('selected')
    // }

    this.clearSelected();

    // Adjust the height of the navigation to match the height of the new panel content
    const navPanelRect = navPanel.getBoundingClientRect()
    this.updatePanelHeight(this.isMobileNav ? '100dvh' : `${navPanelRect.height}px`);

    this.currentNavPanel = navPanel

    this.removeTransitionEventHandlers();

    // Listen for the open transition to finish
    this.navigationElemPanels.addEventListener('transitionend', this.handlePanelTransitionOpen)

    setTimeout(() => {
      document.body.classList.add('navigation-open')
    })
  }

  handlePanelTransitionOpen(event) {

    this.navigationElemPanels.removeEventListener('transitionend', this.handlePanelTransitionOpen)

    if (!this.currentNavPanel) {
      return;
    }

    const delay = this.isHeightIncrease ? this.interactionTimeout : 0;

    setTimeout(() => {
      this.currentNavPanel.classList.add('selected')

      if (this.desktopMq.matches) {
        const firstNavItem = this.currentNavPanel.querySelector('[aria-controls]')
        if (firstNavItem) {
          this.subNavigation.updateActiveFeatures(firstNavItem)
        }
      }
    }, delay)
  }

  handlePanelTransitionClose(event) {
    if (!event.target.classList.contains('navigation-panels')) {
      return;
    }

    this.removeTransitionEventHandlers()

    document.body.classList.remove('navigation-ready')
    document.body.classList.remove('navigation-open')
  }

  close(force) {

    clearBodyLocks();
    this.clearSelected()

    this.navigationElemPanels.addEventListener('transitionend', this.handlePanelTransitionClose)

    this.currentNavPanel = null;
    if (!this.isMobileNav) {
      this.updatePanelHeight('0px')
    } else {
      document.body.classList.remove('navigation-open')
    }

    if (force) {
      this.reset()
    }
  }

  clearSelected() {
    if (this.currentNavPanel) {
      this.currentNavPanel.classList.remove('selected')

      const navFeature = this.currentNavPanel.querySelector('.navigation-panel__panel-feature.selected')
      if (navFeature) {
        navFeature.classList.remove('selected')
      }
    }
  }

  updatePanelHeight(panelHeight) {
    this.isHeightIncrease = this.isOpen() && (parseFloat(panelHeight) > parseFloat(this.panelHeight))
    this.panelHeight = panelHeight

    document.documentElement.style.setProperty('--nav-active-panel-height', panelHeight)
  }

  resize() {
    this.reset()
    this.isMobileNav = this.burger.checkVisibility()
    this.subNavigation.isMobileNav = this.isMobileNav;
    document.body.classList.toggle('navigation-mobile', this.isMobileNav)
  }

  reset() {
    this.navigationElemPanels.style.display = 'none'
    document.body.classList.remove('navigation-ready')
    document.body.classList.remove('navigation-open')
    document.body.classList.remove('navigation-mobile-open')
    document.body.classList.remove('navigation-over')
    this.updatePanelHeight('0px')

    this.subNavigation.reset()
  }

  removeTransitionEventHandlers() {
    this.navigationElemPanels.removeEventListener('transitionend', this.handlePanelTransitionOpen)
    this.navigationElemPanels.removeEventListener('transitionend', this.handlePanelTransitionClose)
  }

  updateEventListeners() {
    console.log('update listeners')
    this.burger.removeEventListener('click', this.handleBurgerClick)

    this.mobileNavItems.forEach((navItem) => {
      navItem.removeEventListener('click', this.handleNavItemClick)
    })

    this.mobileBackButtons.forEach((backBtn) => {
      backBtn.removeEventListener('click', this.handleBackButtonClick)
    })

    this.navItems.forEach((navItem) => {
      navItem.removeEventListener('mouseenter', this.handleNavItemMouseEnter)
      navItem.removeEventListener('mouseleave', this.handleNavItemMouseLeave);
    })

    this.navigationElemPanels.querySelectorAll('.navigation-panels__panel').forEach((panel) => {
      panel.removeEventListener('mouseenter', this.handlePanelsMouseEnter)
      panel.removeEventListener('mouseleave', this.handlePanelsMouseLeave)
    })

    this.subNavigation.updateEventListeners()

    // Re-apply mobile listeners for mobile nav
    if (this.isMobileNav) {
      this.burger.addEventListener('click', this.handleBurgerClick)

      this.mobileNavItems.forEach((navItem) => {
        navItem.addEventListener('click', this.handleNavItemClick)
      })

      this.mobileBackButtons.forEach((backBtn) => {
        backBtn.addEventListener('click', this.handleBackButtonClick)
      })

      return;
    }

    // Re-apply desktop listeners for desktop nav
    this.navItems.forEach((navItem) => {
      navItem.addEventListener('mouseenter', this.handleNavItemMouseEnter)
      navItem.addEventListener('mouseleave', this.handleNavItemMouseLeave);
    })

    this.navigationElemPanels.querySelectorAll('.navigation-panels__panel').forEach((panel) => {
      panel.addEventListener('mouseenter', this.handlePanelsMouseEnter)
      panel.addEventListener('mouseleave', this.handlePanelsMouseLeave)
    })
  }

  handleResize() {
    setTimeout(() => {
      this.resize()
      this.updateEventListeners()
    })
  }

  init() {

    if (!this.navigationElem) {
      return;
    }

    this.resize()

    document.addEventListener('DOMContentLoaded', () => {
      this.updateEventListeners()
    });

    window.addEventListener('resize', this.handleResize)
  }
}

export default Navigation;
