import { Chart } from 'chart.js';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const TITLE_STYLES = {
    display: true,
    fontSize: 25,
    fontFamily: "'VELUX Transform Variable', helvetica, arial, 'Lucida Grande', sans-serif",
};

export default class {
    constructor({
        id,
        leadsHandle,
        adSpendHandle,
        // pageViewsHandle,
        reviewsHandle,
        leadsByYear,
        adSpendLastYear,
        // pageViewsByYear,
        reviews: reviewsRatings,
    }) {
        const el = document.getElementById(id);
        const statsYear = el.querySelector('[name="statsYear"');
        const leads = el.querySelector(leadsHandle);
        const adSpend = el.querySelector(adSpendHandle);
        // const pageViews = el.querySelector(pageViewsHandle);
        const reviews = el.querySelector(reviewsHandle);

        const today = new Date();
        const thisMonth = today.getMonth();
        const thisYear = today.getFullYear();
        const lastYear = thisYear - 1;
        const newMonths = MONTHS
            .filter((_, i) => i <= thisMonth)
            .map(month => new Date(`${month} 01, ${thisYear}`));
        const oldMonths = MONTHS
            .filter((_, i) => i > thisMonth)
            .map(month => new Date(`${month} 01, ${lastYear}`));
        const monthsOneYearBack = oldMonths.concat(newMonths);

        // Leads data chart
        new Chart(leads.getContext('2d'), { // eslint-disable-line no-new
            type: 'bar',
            data: {
                labels: MONTHS,
                datasets: [
                    {
                        label: 'Phone',
                        data: leadsByYear.map(l => l.Phone_Leads),
                        backgroundColor: 'rgba(4,116,244, 1)',
                        borderColor: '#FFFFFF',
                        borderWidth: 1,
                    },
                    {
                        label: 'Email',
                        data: leadsByYear.map(l => l.Email_Leads),
                        backgroundColor: 'rgb(4,179,204)',
                        borderColor: '#FFFFFF',
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                legend: {
                    display: true,
                },
                title: {
                    ...TITLE_STYLES,
                    text: `Leads Generated ${statsYear.value}`,
                },
                scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        },
                    }],
                    xAxes: [{
                        stacked: true,
                        fontSize: 16,
                    }],
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });

        adSpendLastYear = adSpendLastYear.map(data => ({ ...data, date: new Date(data.date) }));
        const adSpendByMonth = monthsOneYearBack
            .map(month => adSpendLastYear
                .find(({ date }) => date.getMonth() === month.getMonth()
                && date.getFullYear() === month.getFullYear()))
            .map(month => (month ? month.spend : 0));

        new Chart(adSpend.getContext('2d'), { // eslint-disable-line no-new
            type: 'bar',
            data: {
                labels: monthsOneYearBack.map(m => MONTHS[m.getMonth()]),
                datasets: [
                    {
                        label: 'Spend',
                        data: adSpendByMonth,
                        backgroundColor: '#7db343',
                        borderColor: '#7db343',
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                title: {
                    ...TITLE_STYLES,
                    text: 'Google Ad Spend Past 12 Months',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                    }],
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });

        // new Chart(pageViews.getContext('2d'), { // eslint-disable-line no-new
        //     type: 'line',
        //     data: {
        //         labels: MONTHS,
        //         datasets: [
        //             {
        //                 label: 'Unique Page Views',
        //                 data: pageViewsByYear.map(d => d.unique_pageviews),
        //                 backgroundColor: 'rgba(4,116,244, 0)',
        //                 borderColor: 'rgba(4,116,244, 1)',
        //                 borderWidth: 1,
        //             },
        //         ],
        //     },
        //     options: {
        //         legend: {
        //             display: false,
        //         },
        //         title: {
        //             ...TITLE_STYLES,
        //             text: `Page Views ${statsYear.value}`,
        //         },
        //         scales: {
        //             yAxes: [{
        //                 stacked: true,
        //                 ticks: {
        //                     beginAtZero: true,
        //                 },
        //             }],
        //             xAxes: [{
        //                 stacked: true,
        //                 fontSize: 16,
        //             }],
        //         },
        //         responsive: true,
        //         maintainAspectRatio: false,
        //     },
        // });

        new Chart(reviews.getContext('2d'), { // eslint-disable-line no-new
            type: 'horizontalBar',
            data: {
                labels: ['5 Star', '4 Star', '3 Star', '2 Star', '1 Star'],
                datasets: [{
                    label: '',
                    data: [
                        reviewsRatings.filter(r => r === 5).length,
                        reviewsRatings.filter(r => r === 4).length,
                        reviewsRatings.filter(r => r === 3).length,
                        reviewsRatings.filter(r => r === 2).length,
                        reviewsRatings.filter(r => r === 1).length,
                    ],
                    backgroundColor: [
                        'rgba(4,116,244, 1)',
                        'rgba(4,116,244, 1)',
                        'rgba(4,116,244, 1)',
                        'rgba(4,116,244, 1)',
                        'rgba(4,116,244, 1)',
                    ],
                    borderWidth: 1,
                }],
            },
            options: {
                legend: {
                    display: false,
                },
                title: {
                    ...TITLE_STYLES,
                    text: 'YEXT Ratings - All Time',
                },
                scales: {
                    xAxes: [{
                        fontSize: 16,
                        ticks: {
                            beginAtZero: true,
                        },
                    }],
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });

        statsYear.addEventListener('change', ({ currentTarget }) => { currentTarget.closest('form').submit(); });
    }
}

