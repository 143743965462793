import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
import swiperSettings from "./swiper-settings";
import gsap from "gsap";

export default class {
  constructor({
    id,
  }) {
    const container = document.getElementById(id);

    this.handleAutplayTimeLeft = this.handleAutplayTimeLeft.bind(this)
    this.handleSlideChange = this.handleSlideChange.bind(this)
    this.handleAutoplayStop = this.handleAutoplayStop.bind(this)

    this.progressMarkers = [...container.querySelectorAll('.full-autoplay-carousel__progress-marker')];

    this.swiper = new Swiper(container.querySelector('.swiper'), {
      ...swiperSettings,
      modules: [Autoplay, Navigation],
      spaceBetween: 0,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      navigation: {
        nextEl: '[data-carousel-right]',
        prevEl: '[data-carousel-left]',
      },
      on: {
        autoplayTimeLeft: this.handleAutplayTimeLeft,
        autoplayStop: this.handleAutoplayStop,
        slideChange: this.handleSlideChange,
      }
    })
  }

  handleAutplayTimeLeft(swiper, timeLeft, percentage) {
    this.progressMarkers[swiper.realIndex].style.setProperty('--autoplay-progress', `${100 - (100 * percentage)}%`)
  }

  handleSlideChange(swiper) {
    gsap.to(this.progressMarkers[swiper.previousRealIndex], { '--autoplay-progress': '0%' })

    setTimeout(() => {
      if (!swiper.autoplay.running) {
        gsap.to(this.progressMarkers[swiper.realIndex], { '--autoplay-progress': '100%' })
      }
    })
  }

  handleAutoplayStop(swiper) {
    gsap.to(this.progressMarkers[swiper.realIndex], { '--autoplay-progress': '100%' })
  }
}
