import * as d3 from "d3";

class PricingBarGraph {
  constructor(container) {
    this.container = container;
    this.updateDimensions()

    this.currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  updateDimensions() {
    const containerRect = this.container.getBoundingClientRect()

    this.viewBoxHeight = containerRect.height
    this.viewBoxWidth = containerRect.width
  }

  init(options) {
    this.options = {
      barBackgroundColor: 'var(--color-residential-grey)',
      barForegroundColor: 'var(--color-logo-red)',
      baseRangeLineColor: 'var(--color-residential-grey)',
      selectedRangeLineColor: 'var(--color-logo-red)',
      tooltipBackground: 'var(--color-black)',
      tooltipTextColor: 'var(--color-white)',
      tooltipTextSize: 10,
      steps: 20,
      barMargin: 7,
      graphMargin: 10,
      lineHeightOffset: 30,
      tooltipWidth: 50,
      tooltipHeight: 20,
      tooltipTailWidth: 5,
      tooltipTailHeight: 5,
      barHeightOffset: 50,
      strokeWidth: '',
      ...(options || {})
    }

    if (this.svg) {
      this.svg.remove()
      this.svg = null;
    }

    this.updateDimensions()
    this.svg = d3.create('svg')

    this.svg.attr('viewBox', `0 0 ${this.viewBoxWidth} ${this.viewBoxHeight}`)
    this.svg.attr('width', '100%')
    this.svg.attr('height', '100%')

    const baseRange = [...this.baseRange];
    const selectedRange = [...this.selectedRange];

    this.x = d3.scaleLinear()
      .domain(baseRange)
      .range([0, this.viewBoxWidth])

    this.y = d3.scaleLinear()
      .domain(baseRange)
      .range([this.viewBoxHeight - (this.options.barHeightOffset + 40), 0])

    // Define a scale with easing applied. This generates a
    // nice curve that's used to generate the haight of each
    // bar for the range
    this.easingScale = d3.scaleLinear()
      .domain([0,1])
      .interpolate(this.easeInterpolate(d3.easeQuadInOut))
      .range(baseRange);

    let range = d3.range(baseRange[0], baseRange[1], (baseRange[1] - baseRange[0]) / this.options.steps)
    let selected = range.map((val) => val >= selectedRange[0] && val <= selectedRange[1] ? val : 0)

    // Generate the eased range using the defined amount of steps and the min/max baseRange
    this.easingRange = range.map((val, i) => parseFloat(this.easingScale(i / (this.options.steps - 1))))

    // Draw the base line
    this.svg.append('line')
      .attr('class', 'base-line')
      .style("stroke", this.options.baseRangeLineColor)
      .style("stroke-width", 2)
      .attr("x1", 0)
      .attr("y1", this.viewBoxHeight - this.options.lineHeightOffset)
      .attr("x2", this.viewBoxWidth)
      .attr("y2", this.viewBoxHeight - this.options.lineHeightOffset);

    // Draw the left base line marker
    this.svg.append('line')
      .attr('class', 'base-line')
      .style("stroke", this.options.baseRangeLineColor)
      .style("stroke-width", 2)
      .attr("x1", 1)
      .attr("y1", this.viewBoxHeight - this.options.lineHeightOffset)
      .attr("x2", 1)
      .attr("y2", this.viewBoxHeight - this.options.lineHeightOffset - 10);

    // Draw the right base line marker
    this.svg.append('line')
      .attr('class', 'base-line')
      .style("stroke", this.options.baseRangeLineColor)
      .style("stroke-width", 2)
      .attr("x1", this.viewBoxWidth - 1)
      .attr("y1", this.viewBoxHeight - this.options.lineHeightOffset)
      .attr("x2", this.viewBoxWidth - 1)
      .attr("y2", this.viewBoxHeight - this.options.lineHeightOffset - 10);


    // Create a group to hold the tooltips
    const tooltips = this.svg.append('g')
      .attr('class', 'tooltips')
      .style('opacity', 0)

    this.createTooltip('range-tip-min', this.selectedRange[0], tooltips)
    this.createTooltip('range-tip-max', this.selectedRange[1], tooltips)

    // Calculate the bar width based on the dimensions of the graph and the amount we need to show
    const steps = (this.viewBoxWidth / this.options.steps) - this.options.barMargin;

    // Create a clipping path for the bottom of the graph.
    // This hides the lower part of the bar during the transition for each bar
    const boundsId = `graph-bounds-${(new Date()).getTime()}`;
    this.svg.append("clipPath")
      .attr("id", boundsId)
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.viewBoxWidth)
      .attr('height', Math.max(this.viewBoxHeight - this.options.barHeightOffset, 0))


    // Render each background bar for the base range
    this.svg.append("g")
      .attr("clip-path", `url(#${boundsId})`)
      .attr("fill", this.options.barBackgroundColor)
      .attr('class', 'base-range')
      .selectAll()
      .data(range)
      .join("rect")
      .attr("x", (d) => this.x(d))
      .attr("y", (d, i) => { return  this.y(this.easingRange[i]) + this.viewBoxHeight })
      .attr("width", steps)

    // Render each selected bar for the selected range
    this.svg.append("g")
      .attr("clip-path", `url(#${boundsId})`)
      .attr("fill", this.options.barForegroundColor)
      .attr('class', 'selected-range')
      .selectAll()
      .data(selected)
      .join("rect")
      .attr("x", (d) => this.x(d))
      .attr("y", (d) => this.viewBoxHeight)
      .attr("width", steps)

    this.container.appendChild(this.svg.node())
  }

  setBaseRange(min, max) {
    this.baseRange = [min, max]
  }

  setSelectedRange(min, max) {
    this.selectedRange = [min, max];
  }

  tween() {
    // Transition each of the bars for the base range
    this.svg.selectAll(".base-range rect")
      .transition()
      .ease(d3.easeCubicInOut)
      .duration(1000)
      .attr("y", (d, i) => this.y(this.easingRange[i]))
      .attr("height", (d, i) => Math.max(this.y(0) - this.y(this.easingRange[i]), 0))
      .delay((d, i) => i * 50)

    // Transition each of the bars for the selected range
    this.svg.selectAll(".selected-range rect")
      .transition()
      .ease(d3.easeCubicInOut)
      .duration(1500)
      .attr("y", (d, i) => this.y(this.easingRange[i]))
      .attr("height", (d, i) => Math.max(this.y(0) - this.y(this.easingRange[i]), 0))
      .delay((d, i) => i * 50 + .5)

    // Find the selected bars in the svg. They'll have a value greater than 0
    const selectedRects = Array.from(this.svg.selectAll('.selected-range rect').filter((d, i) => {
      return d > 0;
    }));


    if (selectedRects.length) {

      // Get the x position of the first selected bar
      const selectedLineStart = parseFloat(selectedRects[0].getAttribute('x'));

      // get the ending x position of the last selected bar
      const lastSelectedRect = selectedRects[selectedRects.length - 1];
      const selectedLineEnd = parseFloat(lastSelectedRect.getAttribute('x')) + parseFloat(lastSelectedRect.getAttribute('width'));

      // Get the center of the tooltip base on its width
      const tooltipCenter = (this.options.tooltipWidth / 2);

      const bottomLineYPosition = this.viewBoxHeight - this.options.lineHeightOffset;

      // Create the selected line starting at a single point (the min position)
      this.svg.selectAll(".tooltips")
        .append('line')
        .attr('class', 'selected-line')
        .style("stroke", this.options.selectedRangeLineColor)
        .style("stroke-width", 1)
        .attr("x1", selectedLineStart)
        .attr("y1", bottomLineYPosition)
        .attr("x2", selectedLineStart)
        .attr("y2", bottomLineYPosition);

      // Prevent the max value tooltip from leaving the view box
      let tooltipStartPos = Math.max(selectedLineStart - tooltipCenter, 0);

      // Adjust the starting position of the min tooltip
      this.svg.selectAll(".range-tip-min")
        .attr("transform", `translate(${tooltipStartPos}, 0)`)

      // Start the max tooltip at the same position as the min tooltip
      this.svg.selectAll(".range-tip-max")
        .attr("transform", `translate(${tooltipStartPos}, 0)`)

      // fade in the group with the tooltips
      this.svg.selectAll(".tooltips")
        .transition(500)
        .delay(1000)
        .style('opacity', 1)

      // Transition the selected line to it's full width
      this.svg.selectAll(".selected-line")
        .transition()
        .ease(d3.easeCubicInOut)
        .duration(1200)
        .attr("x1", selectedLineStart)
        .attr("x2", selectedLineEnd)
        .delay(1000)

      // Prevent the max value tooltip from leaving the view box
      let tooltipEndPos = Math.min(selectedLineEnd - tooltipCenter, this.viewBoxWidth - this.options.tooltipWidth);

      this.transitionTooltips('.range-tip-min', tooltipStartPos, 1000)
      this.transitionTooltips('.range-tip-max', tooltipEndPos, 1000)
    }
  }

  easeInterpolate(ease) {
    return function(a, b) {
      var i = d3.interpolate(a, b);
      return function(t) {
        return i(ease(t));
      };
    };
  }

  createTooltip(cssClass, value, group) {
    // Draw the max tooltip
    const tooltipContainer = group
      .append('g')
      .attr('class', cssClass)

    const tooltip = tooltipContainer.append('g')

    const now = (new Date()).getTime();
    const tooltipClipPathId = `tooltip-clip-${now}`;
    const tooltipContainerClipPathId = `tooltip-container-clip-${now}`

    const tail = tooltipContainer
      .append('path')
      .attr('class', 'tooltip-tail')

    const tailPath = d3.path();
    let tailPathStartY = this.viewBoxHeight - (this.options.lineHeightOffset - 5);

    tailPath.moveTo(0, tailPathStartY)
    tailPath.lineTo(this.options.tooltipTailWidth / 2, tailPathStartY - this.options.tooltipTailHeight)
    tailPath.lineTo(this.options.tooltipTailWidth, tailPathStartY)
    tailPath.lineTo(0, tailPathStartY)
    tailPath.closePath()
    tail.attr('d', tailPath.toString())
    tail.attr('fill', this.options.tooltipBackground)
    tail.attr('transform', `translate(${ (this.options.tooltipWidth / 2) - (this.options.tooltipTailWidth / 2) }, 0)`)

    this.svg.append("clipPath")
      .attr("id", tooltipClipPathId)
      .append("rect")
      .attr("x", 0)
      .attr("y", this.viewBoxHeight - (this.options.lineHeightOffset - 5))
      .attr("width", this.options.tooltipWidth)
      .attr('height', this.options.tooltipHeight)

    this.svg.append("clipPath")
      .attr("id", tooltipContainerClipPathId)
      .append("rect")
      .attr("x", 0)
      .attr("y", this.viewBoxHeight - (this.options.lineHeightOffset - 5) - this.options.tooltipTailHeight)
      .attr("width", this.options.tooltipWidth)
      .attr('height', this.options.tooltipHeight + this.options.tooltipTailHeight)

    tooltip.attr('clip-path', `url(#${tooltipClipPathId})`)
    tooltipContainer.attr('clip-path', `url(#${tooltipContainerClipPathId})`)

    tooltip.append('rect')
      .style("fill", this.options.tooltipBackground)
      .attr("x", 0)
      .attr("y", this.viewBoxHeight - (this.options.lineHeightOffset - 5))
      .attr("width", this.options.tooltipWidth)
      .attr("height", this.options.tooltipHeight)

    const diff = value;
    const minCountValue = value - diff;
    let countValues = d3.range(minCountValue, value, diff / 5);

    countValues.forEach((countValue, i) => {

      const isLast = countValues.length - 1 == i;

      tooltip
        .append('text')
        .attr('class', isLast ? 'value' :  'count-value')
        .attr("x", 0 + this.options.tooltipWidth / 2)
        .attr("y", this.viewBoxHeight)
        .attr('dy', (this.options.tooltipHeight / 2) + 4)
        .attr("text-anchor", "middle")
        .attr("font-family", "VELUX Transform Variable")
        .attr("font-size", this.options.tooltipTextSize)
        .attr('fill', this.options.tooltipTextColor)
        .text(this.currencyFormat.format(isLast ? value : countValue))
    })

    return tooltipContainer
  }

  transitionTooltips(tooltipSelector, x, start) {

    let textStart = start;
    let textDuration = 600;

    this.svg.selectAll(tooltipSelector)
      .transition()
      .duration(1200)
      .delay(1000)
      .attr("transform", `translate(${x}, 0)`)

    let toolTipPosition = (this.options.tooltipWidth / 2) - (this.options.tooltipTailWidth / 2)

    if (x == 0) {
      toolTipPosition = (this.options.tooltipTailWidth / 2) * -1
    } else if (x + this.options.tooltipWidth >= this.viewBoxWidth) {
      toolTipPosition = this.options.tooltipWidth - this.options.tooltipTailWidth - 5;
    }

    this.svg.selectAll(`${tooltipSelector} .tooltip-tail`)
      .transition()
      .duration(1200)
      .delay(1000)
      .attr("transform", `translate(${toolTipPosition}, 0)`)


    let countValues = this.svg.selectAll(`${tooltipSelector} .count-value`)

    countValues
      .transition()
      .duration((d, i) => textDuration + (i * 50))
      .delay((d, i) => textStart + (i * (textDuration * .2)))
      .attr("transform", `translate(0, ${(this.options.tooltipHeight * 2.1) * -1})`)

    this.svg.selectAll(`${tooltipSelector} .value`)
      .transition()
      .duration((d, i) => textDuration + (countValues.size() * 50))
      .delay((d, i) => textStart + (countValues.size() * (textDuration * .2)))
      .attr("transform", `translate(0, ${(this.options.tooltipHeight * 1.25) * -1})`)
  }

}

export default PricingBarGraph;
