import { gsap } from "gsap";

import selfOrClosest from '../../libs/self-or-closest';

export default class {
  constructor({
    id,
    actions,
    events,
    pageComponents,
  }) {

    this.element = document.getElementById(id);

    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleButtonMouseEnter = this.handleButtonMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.mouseEnterTimeout = null;
    this.interactionTimeout = 250;

    this.init()
  }

  handleButtonClick(event) {
    const button = selfOrClosest(event.target, '[aria-controls]')
    this.setActiveItem(button)
  }

  setActiveItem(button) {
    const activeButton = this.element.querySelector('.active [aria-controls]')

    if ((button.closest('[data-accordion-item]') || button.classList.contains('active')) && button.nodeName === 'A') {
      return;
    }

    event.preventDefault()

    if (button == activeButton) {
      this.toggleItemState(activeButton)
      return;
    }

    if (activeButton) {
      this.toggleItemState(activeButton)
    }

    this.toggleItemState(button)
  }

  handleButtonMouseEnter(event) {
    clearTimeout(this.mouseEnterTimeout)
    this.mouseEnterTimeout = setTimeout(() => {
      if (window.matchMedia('(hover: none)').matches) {
        return;
      }

      const button = selfOrClosest(event.target, '[aria-controls]');

      const activeButton = this.element.querySelector('.active [aria-controls]')
      if (activeButton == button) {
        return;
      }

      this.toggleItemState(button)
    }, 250)
  }

  handleMouseLeave(event) {

    if (window.matchMedia('(hover: none)').matches) {
      return;
    }

    const container = selfOrClosest(event.target, '[data-accordion-item]');
    const button = container.querySelector('[aria-controls]')
    this.toggleItemState(button)
  }

  toggleItemState(button) {

    const contentId = button.getAttribute('aria-controls')

    if (!contentId) {
      console.warn(`Invalid content id "${contentId}"`)
      return;
    }

    const itemWrapper = button.closest('[data-accordion-item]')
    const contentElement = itemWrapper.querySelector(`#${contentId}`)

    if (itemWrapper.classList.contains('active')) {
      itemWrapper.classList.remove('active')
      button.setAttribute('aria-expanded', 'false')
      contentElement.style.setProperty('--accordion-item-content-height', '0px');
      return;
    }

    itemWrapper.classList.add('active')
    const itemContent = contentElement.querySelector('[data-accordon-item-content]')
    const itemContentRect = itemContent.getBoundingClientRect()
    button.setAttribute('aria-expanded', 'true')

    contentElement.style.setProperty('--accordion-item-content-height', `${itemContentRect.height}px`);
  }

  toggleItemStateByIndex(index) {
    this.setActiveItem(this.buttons[index])
  }

  closeAll() {
    [...this.element.querySelectorAll('.active')].forEach((active) => {

      const controlElement = active.querySelector('[aria-controls]')
      this.toggleItemState(controlElement)

      // active.classList.remove('active')

      // const accordionItem = selfOrClosest(active, '[data-accordion-item]')

      // if (!accordionItem) {
      //   return;
      // }

      // const controlElement = accordionItem.querySelector('[aria-controls]')

      // if (!controlElement) {
      //   return;
      // }

      // controlElement.setAttribute('aria-expanded', 'false');
      // const contentId = controlElement.getAttribute('aria-controls')
      // const contentElement = accordionItem.querySelector(`#${contentId}`)

      // if (contentElement) {
      //   contentElement.style.setProperty('--accordion-item-content-height', '0px');
      // }
    })
  }

  unload() {
    if (this.buttons) {
      this.buttons.forEach((button) => {
        button.removeEventListener('click', this.handleButtonClick)
        button.removeEventListener('mouseenter', this.handleButtonMouseEnter)
        button.removeEventListener('mouseleave', this.handleMouseLeave)
      })
    }
  }

  init() {

    this.buttons = this.element.querySelectorAll('[aria-controls]')

    this.buttons.forEach((button) => {
      button.addEventListener('click', this.handleButtonClick)

      if (this.element.hasAttribute('data-accordion-hover')) {
        button.addEventListener('mouseenter', this.handleButtonMouseEnter)
        const accordionItemWrapper = button.closest('[data-accordion-item]')
        if (!accordionItemWrapper) {
          console.warn('Accordion button not contained within [data-accordion-item] element')
          return;
        }

        accordionItemWrapper.addEventListener('mouseleave', this.handleMouseLeave)
      }
    })

    if (this.element.hasAttribute('data-accordion-active-item')) {
      const index = parseInt(this.element.dataset.accordionActiveItem) || 0;
      if (this.buttons[index]) {
        this.toggleItemState(this.buttons[index])
      }
    }
  }
}
