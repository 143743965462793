import ModalStack from "./stack";

window.modalStack = window.modalStack || new ModalStack()

class Modal {
  constructor(modal) {
    this.modal = modal;
    this.closeTriggers = [...this.modal.querySelectorAll('[data-modal-close]')]
    this.heading = this.modal.querySelector('[data-modal-heading]')

    this.handleCloseTriggerClick = this.handleCloseTriggerClick.bind(this)

    this.handleModalClick = this.handleModalClick.bind(this)
    this.navigation = null;
    this.pageComponents = null;
  }

  setNavigation(navigation) {
    this.navigation = navigation;
  }

  // @TODO - CG Temporary until Modals become a page component themselves
  setPageComponents(pageComponents) {
    this.pageComponents = pageComponents;
  }

  handleCloseTriggerClick(event) {
    this.close()
  }

  get isOpen() {
    return !this.modal.classList.contains('hidden')
  }

  open() {
    if (this.navigation) {
      this.navigation.close(true)
    }

    return new Promise((resolve) => {
      // prep the modal to be revealed
      this.modal.classList.remove('hidden')
      setTimeout(() => {
        resolve();
        window.modalStack.push(this)
      })
    })
  }

  close() {
    this.modal.classList.add('hidden')
    this.resetEventHandlers()

    // Return focus to the trigger element
    if (this.trigger) {
      this.trigger.focus();
    }

    // Remoe the modal from the stack incase it's somehow closed out of order
    window.modalStack.remove(this)

    //this.modal.triggerEvent(new CustomEvent('close'), { detail: this })
  }

  handleModalClick(event) {
    if (event.target.closest('[data-modal-content]')) {
      return;
    }

    this.close()
  }

  resetEventHandlers() {
    this.closeTriggers.forEach((closeTrigger) => {
      closeTrigger.removeEventListener('click', this.handleCloseTriggerClick)
      closeTrigger.addEventListener('click', this.handleCloseTriggerClick)
    })

    this.modal.removeEventListener('click', this.handleModalClick);
    this.modal.addEventListener('click', this.handleModalClick);
  }

  init(trigger) {
    this.trigger = trigger;
    this.resetEventHandlers()
    return Promise.resolve();
  }
}

export default Modal;
