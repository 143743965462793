const IS_TOUCH = 'ontouchstart' in window;
const EVENTS = {
    POINTER_DOWN: IS_TOUCH ? 'touchstart' : 'mousedown',
    POINTER_MOVE: IS_TOUCH ? 'touchmove' : 'mousemove',
    POINTER_UP: IS_TOUCH ? 'touchend' : 'mouseup',
    POINTER_OUT: IS_TOUCH ? 'touchleave' : 'mouseout',
};

export default class {
  constructor({
    id,
    beforeImageHandle,
    dragHandle
  }) {
    this.el = document.getElementById(id);

    this.beforeImage = this.el.querySelector(`.${beforeImageHandle}`);
    this.dragControl = this.el.querySelector(`.${dragHandle}`);

    // State variables
    this.handleResize();

    // Event handler functions
    this.handleResize = this.handleResize.bind(this);
    this.handlePointerMove = this.handlePointerMove.bind(this);
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handlePointerUp = this.handlePointerUp.bind(this);

    // Add event listeners
    window.addEventListener('resize', this.handleResize);
    this.dragControl.addEventListener(EVENTS.POINTER_DOWN, this.handlePointerDown);
    this.el.addEventListener(EVENTS.POINTER_UP, this.handlePointerUp);
    this.dragControl.addEventListener(EVENTS.POINTER_UP, this.handlePointerUp);
  }

  destroy() {
    // Remove event listeners
    window.removeEventListener('resize', this.handleResize);
    this.dragControl.removeEventListener(EVENTS.POINTER_DOWN, this.handlePointerDown);
    this.el.removeEventListener(EVENTS.POINTER_UP, this.handlePointerUp);
    this.dragControl.removeEventListener(EVENTS.POINTER_UP, this.handlePointerUp);

    // destroy vars
    delete this.el;
    delete this.beforeImage;
    delete this.dragControl;
    delete this.contWidth;
    delete this.offset;
    delete this.min;
    delete this.max;
  }

  handleResize() {
    // Reset state variables
    this.contWidth = this.el.offsetWidth;
    this.offset = this.el.getBoundingClientRect().left;
    this.min = this.offset;
    this.max = this.offset + this.contWidth;
  }

  handlePointerMove(e) {
    // reset state vars to account for image comparisons inside of a carousel
    this.handleResize();

    let clientX = e.clientX || e.touches[0].clientX;

    if (clientX < this.min) {
        clientX = this.min;
    } else if (clientX > this.max) {
        clientX = this.max;
    }

    const percent = `${((clientX - this.offset) / this.contWidth) * 100}%`;

    this.beforeImage.style.width = percent;
    this.dragControl.style.left = percent;
  }

  handlePointerDown() {
    this.el.addEventListener(EVENTS.POINTER_MOVE, this.handlePointerMove);
  }

  handlePointerUp() {
    this.el.removeEventListener(EVENTS.POINTER_MOVE, this.handlePointerMove);
  }
}
