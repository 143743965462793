import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        type,
        name,
        conditionalName,
        conditionalValue,
        conditionalOptionsUrl = '',
        overlayLabelsClass,
        focusClass,
        actions,
        events,
    }) {

    this.overlayLabelsClass = overlayLabelsClass;
    this.focusClass = focusClass;

    this.container = document.getElementById(id);

    if (!this.container) {
      console.warn(`${id} does not exist for input component`)
    }

    this.input = type === 'checkbox' || type === 'radio'
      ? this.container.querySelector(`[name*="${name}[]"]`)
      : this.container.querySelector(`[name="${name}"]`);

    if (!this.input) {
      console.warn(`${id} does not contain a form element`)
      return;
    }

    this.form = this.input.closest('form');

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)

    const targets = conditionalName
        ? this.form.querySelectorAll(`[name="${conditionalName}"]`)
        : [];

    // events.on(actions.setInputValue, handleSetInputValue);
    targets.forEach(target => {
      target.addEventListener('change', this.handleTargetChange);
    });

    this.input.addEventListener('focus', this.handleFocus);
    this.input.addEventListener('blur', this.handleBlur);
    this.input.addEventListener('change', this.handleChange);

    // // Initalize
    this.handleChange();

    if (conditionalName) {
      const formData = new FormData(form);
      this.toggleVisibility(this.testConditional(formData.get(conditionalName), conditionalValue));
    }
  }

  handleChange(event) {

    if (!this.container) {
      return;
    }

    const tags = ['INPUT'];
    const types = ['text', 'email', 'password'];

    if (tags.includes(this.input.tagName) && types.includes(this.input.getAttribute('type'))) {
      if (this.input.value !== '') {
        this.container.classList.add('has-value');
        return;
      }

      this.container.classList.remove('has-value');
    }
  }

  handleSetInputValue(event) {
    const { name: n, value } = event.detail;

    if (n === name) {
      this.input.value = value;
    }
  }

  handleTargetChange(event) {
    const { value } = event.currentTarget;
    const show = this.testConditional(value, conditionalValue);

    // Check if we need to fetch dynamic options before showing input
    if (conditionalOptionsUrl === '') {
        this.toggleVisibility(show);
        return;
    }

    // No need to request dynamic options if input is hidden
    if (!show) {
        this.toggleVisibility(show);
        return;
    }

    // Get dynamic options
    const url = conditionalOptionsUrl;
    const query = { value };

    function cb(res) {
      const { options } = JSON.parse(res);

      // Populate county select w/ options
      if (options.length) {
        input.innerHTML = options
          .map(({ optValue, optLabel }) => `<option value="${optValue}">${optLabel}</option>`)
          .join('');
      }

      this.toggleVisibility(show);
    }

    router.get({ url, query, cb });
  }

  handleFocus(event) {
    this.container.classList.add(this.focusClass);
  }

  handleBlur(event) {
    this.container.classList.remove(this.focusClass);
  }

  toggleVisibility(visible) {
    this.container.style.display = visible ? 'block' : 'none';
  }

  testConditional(val, match) {
    switch (typeof match) {
      case 'string': return val === match;
      case 'object': return match.includes(val);
      default: return false;
    }
  }

  clearErrors() {
    this.container.classList.remove('has-error')
    this.container.querySelector('.error-message').innerHTML = '';
  }

  setErrors(errors) {
    if (errors.length > 0) {
      this.container.classList.add('has-error')
    }
    this.container.querySelector('.error-message').innerHTML = (errors || []).join(', ')
  }

  reset() {
    this.clearErrors()
  }
}
