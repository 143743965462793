import WidgetSwitch from '../transformation/widget-switch';

class OptionToggle {

    constructor (el) {
      this.el = el

      this.switch = new WidgetSwitch(
        this.el.querySelector('.widget-switch')
      )

      this.el.addEventListener('click', this.handleClick.bind(this))
      this.set('option-a')
    }

    handleClick (e) {
      e.preventDefault()
      if (this.el.classList.contains('option-a')) {
        this.set('option-b')
      } else {
        this.set('option-a')
      }
    }

    set (state) {
      if (!state) {
        state = this.el.classList.contains('option-b') ? 'option-a' : 'option-b'
      }
      if (state == 'option-a') {
        this.el.classList.remove('option-b')
        this.el.classList.add('option-a')
        this.switch.before()
      } else {
        this.el.classList.remove('option-a')
        this.el.classList.add('option-b')
        this.switch.after()
      }
    }
}

function initOptionToggles () {
  const init = function() {
    const els = document.querySelectorAll('.option-toggle')
    if (els) {
      els.forEach((el) => {
        console.log(el)
        new OptionToggle(el)
      })
    }
  }

  if (document.readyState === 'complete') {
    init();
  }

  document.addEventListener('DOMContentLoaded', () => {
    init()
  })
}

export { OptionToggle, initOptionToggles }
