import Masonry from "masonry-layout";
import domContentLoaded from '../events/dom-content-loaded';

function imagesLoaded(container) {
  const images = [...container.querySelectorAll('img')]
  let load = 0;

  return new Promise((resolve) => {

    this.timeout = setTimeout(() => {
      resolve()
    }, 250)

    images.forEach((image) => {
      image.onload = (event) => {
        clearTimeout(this.timeout)
        if (event.target.naturalWidth !== 0) {
          this.timeout = setTimeout(() => {
            resolve()
          }, 250)
        }
      }
    })
  })
}

export default class {
  constructor({
      id,
  }) {

    this.mq = window.matchMedia('(min-width: 768px)')
    this.el = document.getElementById(id)

    this.viewMoreBtn = this.el.querySelector('button.view-more');
    this.viewLessBtn = this.el.querySelector('button.view-less');

    this.gridItems = [...this.el.querySelectorAll('.grid-item')];

    this.handleViewMoreClick = this.handleViewMoreClick.bind(this)
    this.handleViewLessClick = this.handleViewLessClick.bind(this)

    if (this.viewMoreBtn) {
      this.viewMoreBtn.addEventListener('click', this.handleViewMoreClick)
      this.viewLessBtn.addEventListener('click', this.handleViewLessClick)
    }

    this.layout = null;

    this.mq.addEventListener('change', () => {
      this.updateGrid();
    })

    if (this.mq.matches) {
      this.updateGrid()
    }
  }

  updateGrid() {
    if (this.mq.matches) {
      domContentLoaded().then(() => {
        imagesLoaded(this.el).then(() => {
          this.layout = new Masonry(this.el.querySelector('.grid-wrapper'), {
            itemSelector: '.grid-item',
            columnWidth: '.grid-item',
            percentPosition: true,
            gutter: 12,
          });
        })
      })
      return;
    }

    if (this.layout) {
      this.layout.destroy();
      this.layout = null;
    }
  }

  handleViewMoreClick(event) {
    this.gridItems.forEach((item) => {
      item.classList.remove('hidden')
    })

    this.viewLessBtn.classList.remove('hidden')
    this.viewMoreBtn.classList.add('hidden')
  }

  handleViewLessClick(event) {
    this.gridItems.slice(3).forEach((item) => {
      item.classList.add('hidden')
    })

    this.viewLessBtn.classList.add('hidden')
    this.viewMoreBtn.classList.remove('hidden')
  }
}
