import gsap from "gsap";
import { runMotionGroup } from "../site-motion";

class Testimonial {
  constructor(container, contentBlock, mediaBlock) {
    this.container = container;
    this.contentBlock = contentBlock;
    this.mediaBlock = mediaBlock;
  }

  init() {
  }

  show(delay) {
    this.mediaBlock.classList.add('active')

    setTimeout(() => {
      gsap.set([this.contentBlock], { visibility: '', opacity: '' })

      this.contentBlock.classList.add('active')

      runMotionGroup(this.contentBlock)
    }, delay || 0)
  }

  hide() {
    gsap.to([this.contentBlock], {
      autoAlpha: 0,
      duration: .5,
      onComplete: () => {
        this.contentBlock.classList.remove('active')
      }
    })

    this.mediaBlock.classList.remove('active')
  }
}

export default Testimonial;
