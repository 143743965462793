
export default function() {

  document.addEventListener('DOMContentLoaded', () => {

    let eles = document.querySelectorAll('[data-caption][data-target-id]')

    if (!eles) {
      return;
    }

    function doOver (e) {
      const txt = this.dataset.caption;
      const id = this.dataset.targetId;
      if (id && txt) {
        const ele = document.getElementById(id)
        if (ele) {
          ele.innerHTML = txt;
        }
      }
    }
    function doOut (e) {
      const id = this.dataset.targetId;
      if (id) {
        const ele = document.getElementById(id)
        if (ele) {
          ele.innerHTML = '&nbsp;';
        }
      }
    }
    eles.forEach((ele) => {
      ele.addEventListener("mouseover", doOver);
      ele.addEventListener("mouseout", doOut);
      ele.addEventListener("touchend", doOver);
    });
  })
}
