import Swiper from "swiper"
import swiperSettings from "./swiper-settings"

class TransformationsCarousel {


  constructor(container) {
    this.container = container

    this.leftBtn = container.querySelector('[data-transformations-carousel-left]')
    this.rightBtn = container.querySelector('[data-transformations-carousel-right]')

    this.swiper = new Swiper(container.querySelector('.swiper'), {
      ...swiperSettings,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      breakpoints: {
      }
    })

    if (this.leftBtn && this.rightBtn) {
      this.leftBtn.addEventListener('click', () => {
        this.swiper.slidePrev()
      })

      this.rightBtn.addEventListener('click', () => {
        this.swiper.slideNext()
      })
    }
  }

}

export default function() {

  document.addEventListener('DOMContentLoaded', () => {

    const sections = [...document.querySelectorAll('.transformations-carousel')]

    if (sections.length === 0) {
      return
    }

    sections.forEach((section) => {
      new TransformationsCarousel(section)
    })
  })
}
