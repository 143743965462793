import { initNewDropdowns } from '../forms/control-dropdown';
import Form from '../components/form';

/*
  Original Form Reference
  https://whyskylights.com/register

  */

export default class extends Form {
  constructor(params) {

    super(params)

    this.formElement = this.el;
    this.addProduct = this.addProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.init();
  }

  init() {
    this.tmplEle = this.formElement.querySelector('#product-registration-template');
    this.itemsEle = this.formElement.querySelector('#product-registration-items');
    this.addBtn = this.formElement.querySelector('#product-registration-items-add');

    this.formElement.addEventListener('click', this.removeProduct);
    this.submitBtn.addEventListener('submit', this.formElement.submit);
    this.addBtn.addEventListener('click', this.addProduct);
    this.addProduct();
  }

  addProduct() {
    const cnt = this.itemsEle.querySelectorAll('[data-product]');
    let tpl = this.tmplEle.innerHTML.replaceAll('{key}', cnt.length);
    const ndx = (cnt.length + 1);
    tpl = tpl.replaceAll(':n', 0);
    this.itemsEle.insertAdjacentHTML('beforeend', tpl);
    this.rekey();

    initNewDropdowns();
  }

  removeProduct(e) {
    const ndx = e.target.parentElement.dataset.remove | false;
    if (ndx) {
      e.preventDefault();
      const ele = e.target.closest('div[data-product]');
      if (ele) {
        ele.remove();
        this.rekey();
      }
    }
  }

  displayErrors(errors) {
    // First, handle general errors (fields that exist only once)
    if (errors.generalErrors) {
      for (const [fieldName, fieldErrors] of Object.entries(errors.generalErrors)) {
        let inputFields = this.formElement.elements[fieldName];

        if (!inputFields) {
          // Try to find elements whose name starts with fieldName + '['
          inputFields = [];
          for (const element of this.formElement.elements) {
            if (element.name && element.name.startsWith(`${fieldName}[`)) {
              inputFields.push(element);
            }
          }
          if (inputFields.length === 0) {
            inputFields = null;
          }
        }

        if (inputFields) {
          let inputField;

          // Handle collections (e.g., NodeList or array of elements)
          if (inputFields.length !== undefined) {
            if (!inputFields.tagName) {
              inputField = inputFields[0];
            } else {
              inputField = inputFields;
            }
          } else {
            inputField = inputFields;
          }

          // Find the closest '.form-field.dropdown' or '.form-field' parent
          const inputCont = inputField.closest('.form-field.dropdown') || inputField.closest('.form-field');

          if (inputCont) {
            inputCont.classList.add('has-error');
            const errorMessage = inputCont.querySelector('.error-message')

            if (errorMessage) {
              errorMessage.innerHTML = fieldErrors.join("<br>")
            }
          }
        }
      }
    }

    // Now handle prSkylightErrors (indexed fields like prSkylightType[0], prSkylightRoom[1], etc.)
    if (errors.prSkylightErrors && Array.isArray(errors.prSkylightErrors)) {
      errors.prSkylightErrors.forEach((skylightErrors, index) => {
        for (const [fieldName, fieldErrors] of Object.entries(skylightErrors)) {

          const indexedFieldName = `${fieldName}[${index}]`;
          let inputFields = this.form.elements[indexedFieldName];

          if (!inputFields) {
            // Try to find elements with the exact indexed field name
            inputFields = [];
            for (const element of this.formElement.elements) {
              if (element.name && element.name === indexedFieldName) {
                inputFields.push(element);
              }
            }
            if (inputFields.length === 0) {
              inputFields = null;
            }
          }

          if (inputFields) {
            let inputField;

            // Handle collections (e.g., NodeList or array of elements)
            if (inputFields.length !== undefined) {
              if (!inputFields.tagName) {
                inputField = inputFields[0];
              } else {
                inputField = inputFields;
              }
            } else {
              inputField = inputFields;
            }

            const inputCont = inputField.closest('.form-field.dropdown') || inputField.closest('.form-field.input__input--group');

            if (inputCont) {

              inputCont.classList.add('has-error');

              let errorContainer;

              // First, try to select the '.error-message' that is a sibling of '.relative' within inputCont
              if (inputCont.classList.contains('dropdown')) {
                errorContainer = inputCont.querySelector(':scope > div > .error-message');
              } else if (inputCont.classList.contains('input__input--group')) {
                errorContainer = inputCont.querySelector(':scope > .error-message');
              }

              if (errorContainer) {
                errorContainer.innerHTML = fieldErrors.join("<br>")
              }
            }
          }
        }
      });
    }
  }


  /**
   * Rekey product items, e.g., field names, IDs, and anchor names
   */
  rekey() {
    const products = this.itemsEle.querySelectorAll('[data-product]');
    products.forEach((product, i) => {
      product.dataset.remove = i;

      // Update label to reflect the product index
      const lbl = product.querySelector('[data-ndx]');
      if (lbl) {
        lbl.innerHTML = `Product ${i + 1}`;
      }

      // Update anchor name
      const anchor = product.querySelector('a[name]');
      if (anchor) {
        anchor.name = `product_${i + 1}`;
      }

      // Add prSkylight[i] with the index value
      let skylightInput = product.querySelector('input[name^="prSkylight"]');
      if (!skylightInput) {
        skylightInput = document.createElement('input');
        skylightInput.type = 'hidden';
        product.appendChild(skylightInput);
      }
      skylightInput.name = `prSkylight[${i}]`;
      skylightInput.value = i; // Assign the index as the value

      // Update input fields to use the new naming convention
      const inputs = product.querySelectorAll('input[type="radio"]');
      inputs.forEach(input => {
        const oldName = input.name;

        if (oldName.endsWith(`item.${i}.productType`)) {
          input.name = `prSkylightType[${i}]`;
        } else if (oldName.endsWith(`item.${i}.productTypeContext`)) {
          input.name = `prSkylightStatus[${i}]`;
        } else if (oldName.endsWith(`item.${i}.roomType`)) {
          input.name = `prSkylightRoom[${i}]`;
        } else if (oldName.endsWith(`item.${i}.roomTypeContext`)) {
          input.name = `prSkylightHome[${i}]`;
        }
      });
    });
  }

  handleErrorResponse(response) {
    super.handleErrorResponse(response)
    this.displayErrors(response.errors)
  }
}
