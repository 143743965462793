import { router, throttle } from 'ui-utilities';

export default class {
    constructor({
        id,
        reviewHandle,
        moreHandle,
        lessHandle,
        activeClass,
        reviewPageId,
        initialReviewLimit,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const reviews = [...el.querySelectorAll('.review')];
        const more = el.querySelector(moreHandle);
        const less = el.querySelector(lessHandle);

      function handleMore() {
        if (!el.classList.contains(activeClass)) {
          el.classList.add(activeClass)

          reviews.forEach((review) => {
            review.parentNode.classList.remove('hidden')
          })
        } else {
          el.classList.remove(activeClass)
          reviews.slice(3).forEach((review) => {
            review.parentNode.classList.add('hidden')
          })
        }
      }

      if (more) {
        more.addEventListener('click', handleMore);
      }

      if (less) {
        less.addEventListener('click', handleMore);
      }
    }
}
