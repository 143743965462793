class Events {
  constructor() {
    this.handlers = [];
  }

  on(handle, cb, target = window) {
    target.addEventListener(handle, cb);
    this.handlers.push(cb)
  }

  emit(handle, payload, target = window) {
    const event = new CustomEvent(handle, { detail: payload });
    target.dispatchEvent(event);
  }

  off(handle, target = window) {
    this.handlers.forEach((cb) => {
      target.removeEventListener(handle, cb)
    })
  }
}

class PageComponents {

  constructor({
    componentClassMap = {},
    actions = {},
  }) {
    // A map of handles to their related javascript component classes
    this.componentClassMap = componentClassMap;

    // Backward Compatibility: A map of global actions that can be performed by components
    this.actions = actions;

    // Global state value passed to each component
    this.state = {}

    // All available components on the page
    this.components = [];

    // Ported from compop. Some components use this
    // to emit and listen for events on target elements
    this.events = new Events()
  }

  build(container) {

    // Pull elements registered as a component. If a container is not provided, it's assumed
    // the the components for the entire page need to be registered.
    const components = [...(container || document).querySelectorAll('[data-component]')].map((componentElem) => {
      return { element: componentElem, config: JSON.parse(componentElem.dataset.component)}
    });

    // Iterate through the array of component definitions and create their instances
    const newInstances = components.map((component) => {

      const { element, config } = component;

      // Find the component class/function in the component class map.
      const ComponentClass = this.componentClassMap[config.handle]
      if (typeof ComponentClass !== 'function') {
        console.warn(`${config.handle} is not defined in the component class map`)
        return;
      }

      const instance = new ComponentClass({
        ...config,
        state: this.state,
        events: this.events,
        actions: this.actions,
        pageComponents: this,
      });

      // Attach references to the component element and type
      // This is used for finding related components later
      instance.componentElement = element;
      instance.componentType = config.handle
      instance.componentId = config.id || null

      this.components.push(instance)

      return instance
    })

    // Notify all the new components that everything has been created
    newInstances.forEach((component) => {
      if (component && typeof component.handleComponentsCreated === 'function') {
        component.handleComponentsCreated()
      }
    })
  }

  findNestedComponents(container, type) {
    return this.components.filter((instance) => instance.componentType == type && container.contains(instance.componentElement))
  }

  getComponentById(id) {
    return this.components.find((instance) => instance.componentId == id)
  }

  getComponentByType(container, type) {
    return this.components.find((instance) => instance.componentType == type && container.contains(instance.componentElement))
  }
}

export default PageComponents;
