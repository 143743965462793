import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function() {

  const heroContent = document.querySelector('.hero-content')
  const heroMedia = document.querySelector('.hero-media')

  const mobileMq = window.matchMedia('(min-width: 980px)')

  function handleMobileMqChange() {

    if (!mobileMq.matches) {
      return;
    }

    gsap.to(heroMedia, {
      scrollTrigger: {
        trigger: heroContent,
        scrub: true,
        start: 'top top',
        end: 'bottom top'
      },
      y: '-60%'
    })
  }

  mobileMq.addEventListener('change', handleMobileMqChange)
  handleMobileMqChange();
}
