import TransitionModal from "../transition"

class ProjectCalculatorLeads extends TransitionModal {
  constructor(modal) {
    super(modal)

    this.form = modal.querySelector('form')
    this.leadHeader = modal.querySelector('[data-modal-header]')

    this.handleFormResponse = this.handleFormResponse.bind(this)
    this.handleFormRequest = this.handleFormRequest.bind(this)
  }

  open() {
    return this.updateContent().then(() => {
      return super.open()
    })
  }

  close() {
    return super.close().then(() => {
      if (this.formComponent) {
        this.formComponent.reset()
        this.leadHeader.classList.remove('hidden')
      }
    })
  }

  updateContent() {
    // Get the instance of the form component
    this.formComponent = this.pageComponents.getComponentByType(this.modal, 'form')
    this.formComponent.setResponseHandler(this.handleFormResponse)
    this.formComponent.setRequestHandler(this.handleFormRequest)
    this.createUpdateInstallerIdField(this.trigger.dataset.installerIdHash)

    this.modal.querySelectorAll('[data-installer-name]').forEach((installerNameElem) => {
      installerNameElem.innerHTML = this.trigger.dataset.installerName
    })

    // Update the modal header
    this.heading.innerHTML = this.trigger.dataset.installerName
    return Promise.resolve()
  }

  // Adds the installer id field to the contact form
  createUpdateInstallerIdField(installerId) {
    let installerIdField = this.form.querySelector('input[name="installer_id"]')
    if (!installerIdField) {
      installerIdField = document.createElement('input')
      installerIdField = this.form.appendChild(installerIdField)
      installerIdField.setAttribute('name', 'installer_id')
      installerIdField.setAttribute('type', 'hidden')
    }

    installerIdField.setAttribute('value', installerId)
  }

  handleFormRequest(request) {

    const formData = request.body

    // Merge the project calculator results to the request
    try {
      const calculatorResults = JSON.parse(sessionStorage.getItem('projectCalculatorResults'));

      Object.keys(calculatorResults).forEach((resultKey) => {
        formData.append(resultKey, calculatorResults[resultKey])
      })

    } catch(e) {
      console.warn(e)
    }

    return Promise.resolve(request)
  }

  handleFormResponse(response) {
    if (response.success) {
      this.leadHeader.classList.add('hidden')
    }

    return Promise.resolve(response)
  }
}


export default ProjectCalculatorLeads;
