
export default class WidgetSwitch {

    constructor (ele) {
      this.ele = ele;
      this.inpts = this.ele.querySelectorAll("input");
      if (this.ele.classList.contains("after")) {
        this.after();
      }
    }

    before() {
      this.inpts[0].setAttribute('checked', 'true')
      this.inpts[0].checked = true;

      this.inpts[1].removeAttribute('checked')
      this.inpts[1].checked = false;
    }

    after() {
      this.inpts[0].removeAttribute('checked')
      this.inpts[0].checked = false;

      this.inpts[1].setAttribute('checked', 'true')
      this.inpts[1].checked = true;
    }

    getState () {
      this.inpts.forEach((inpt) => {
        if (inpt.checked) {
          return inpt.value.toLowerCase();
        }
      });
    }

    // setState (state) {
    //   this.inpts.forEach((inpt) => {
    //     if (inpt.value.toLowerCase() == state.toLowerCase()) {
    //       inpt.setAttribute('checked', '')
    //       inpt.checked = true;
    //       return;
    //     }

    //     inpt.removeAttribute('checked')
    //     inpt.checked = false;
    //   });
    // }
}

