import Swiper from "swiper";
import swiperSettings from "../swiper-settings";
import gsap from "gsap";
import { runMotionGroup } from "../site-motion";

class ProjectRegion {

  constructor(container, contentBlock, mediaBlock) {
    this.container = container;
    this.contentBlock = contentBlock;
    this.mediaBlock = mediaBlock;

    this.leftBtn = this.mediaBlock.querySelector('[data-featured-project-left]')
    this.rightBtn = this.mediaBlock.querySelector('[data-featured-project-right]')
  }

  init() {
    this.swiper = new Swiper(this.mediaBlock.querySelector('.swiper'), {
      ...swiperSettings,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      breakpoints: {
      }
    });

    this.leftBtn.addEventListener('click', () => {
      this.swiper.slidePrev()
    })

    this.rightBtn.addEventListener('click', () => {
      this.swiper.slideNext()
    })
  }

  show(delay) {
    setTimeout(() => {
      gsap.set([this.contentBlock, this.mediaBlock], { visibility: '', opacity: '' })

      this.contentBlock.classList.add('active')
      this.mediaBlock.classList.add('active')
      runMotionGroup(this.contentBlock)
      runMotionGroup(this.mediaBlock)
    }, delay || 0)
  }

  hide() {
    gsap.to([this.contentBlock, this.mediaBlock], {
      autoAlpha: 0,
      duration: .5,
      onComplete: () => {
        this.contentBlock.classList.remove('active')
        this.mediaBlock.classList.remove('active')
      }
    })
  }
}

export default ProjectRegion
