import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import swiperSettings from '../../components/swiper-settings';

export default class {
  constructor({
      id,
  }) {

    const el = document.getElementById(id);
    const swiperContainer = el.querySelector('.swiper');

    const swiper = new Swiper(swiperContainer, {
      ...swiperSettings,
      modules: [Navigation],
      autoHeight: true,
      slidesPerView: 1,
      spaceBetween: 0,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    // Initialize
    swiper.init();
  }
}
