// Imports
import { router } from 'ui-utilities';

// Global variables

// Class
export default class {
  constructor({
    id,
    actions,
    events,
    pageComponents,
  }) {

    this.pageComponents = pageComponents

    // Elements and class variables
    this.el = document.getElementById(id);

    this.hotspots = [...this.el.querySelectorAll('button[data-feature-index]')];
    this.hotspotTooltips = this.hotspots.map((hotspot) => document.getElementById(`feature-index-${hotspot.dataset.featureIndex}`))

    this.handleHotspotClick = this.handleHotspotClick.bind(this)
    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.handleBodyClick = this.handleBodyClick.bind(this)

    // Attach event listeners
    this.hotspots.forEach((hotspot, i) => {
      hotspot.addEventListener('click', this.handleHotspotClick)
      const closeBtn = this.hotspotTooltips[i].querySelector('[data-modal-close]')
      closeBtn.addEventListener('click', this.handleCloseClick)
    });

    this.desktopMq = window.matchMedia('(min-width:980px)')

    document.body.addEventListener('click', this.handleBodyClick)
  }

  handleHotspotClick(event) {
    event.preventDefault()
    this.showFeatureByIndex(event.currentTarget.dataset.featureIndex)

    const accordions = this.pageComponents.findNestedComponents(this.el, 'accordion')
    if (accordions.length > 0) {
      accordions[0].toggleItemStateByIndex(event.currentTarget.dataset.featureIndex)

      if (!this.desktopMq.matches) {
        console.log('not desktop')
        accordions[0].element.scrollIntoView(false)
      }
    }
  }

  handleCloseClick(event) {
    this.closeContent()
  }

  showFeatureByIndex(index) {
    this.hotspotTooltips.forEach((tooltip, i) => {
      if (index == i) {
        tooltip.classList.remove('hidden')
        return;
      }

      tooltip.classList.add('hidden')
    })
  }

  handleBodyClick(event) {
    if (event.target.closest('.info') || event.target.matches('.product-features-map__hotspot')) {
      return;
    }

    this.closeContent()
  }

  closeContent() {
    this.hotspotTooltips.forEach((tooltip) => {
      tooltip.classList.add('hidden')
    })
  }
}

