import { load as loadRecaptcha } from 'recaptcha-v3';

const RECAPTCHA_SITE_KEY = '6LeTCUEqAAAAANgTkhj9HeAv2KtCUjJkPc9QDAMh';

export default class {
  constructor({
    id,
    useAjax,
    pageComponents,
  }) {
    this.el = document.getElementById(id);
    this.form = this.el.querySelector('form');
    this.useAjax = useAjax

    this.formMessages = this.el.querySelector('.form__messages')

    this.pageComponents = pageComponents

    if (!this.form) {
      return;
    }

    this.recaptcha = null;

    // Reference to the submit button and its copy element
    // Allows for updating the button during loading states
    this.submitBtn = this.form.querySelector('[type="submit"]')
    this.submitBtnCopy = this.submitBtn.querySelector('.btn-copy')

    if (this.submitBtnCopy) {
      this.submitBtn.dataset.label = this.submitBtnCopy.innerHTML
    }

    this.handleSubmit = this.handleSubmit.bind(this)


    // Add event handlers
    // Load reCAPTCHA object from key
    loadRecaptcha(RECAPTCHA_SITE_KEY).then(r => {
      this.recaptcha = r;
    });

    this.form.addEventListener('submit', this.handleSubmit);
  }

  // Handle form submission. Retrieves a token from recaptcha
  // before submitting the form
  handleSubmit(event) {

    if (this.recaptcha === null) {
      return;
    }

    event.preventDefault()

    this.toggleLoadingState(true)

    // Get reCAPTCHA score token
    this.recaptcha.execute('form').then(token => {
      return this.submit({ recaptchaToken: token })
    });
  }

  // Single method for handling ajax and non-ajax
  // form submissions
  submit(params) {
    if (this.useAjax) {
      return this.submitWithAjax(params)
    }

    return this.submitWithoutAjax(params)
  }

  // Standard form submission. Append the recaptcha token input to the form and submit.
  submitWithoutAjax(params) {
    let recaptchaTokenInput = this.form.querySelector('input[name="token"]')
    if (!recaptchaTokenInput) {
      recaptchaTokenInput = document.createElement('input')
      recaptchaTokenInput.setAttribute('type', 'hidden')
      recaptchaTokenInput.setAttribute('name', 'token')
      this.form.appendChild(recaptchaTokenInput)
    }

    recaptchaTokenInput.setAttribute('value', params.recaptchaToken)

    this.form.submit()
    return Promise.resolve();
  }

  // Ajax submission. Get current session info and submit the request via ajax
  submitWithAjax(params) {
    const formData = new FormData(this.form)
    formData.append('token', params.recaptchaToken || '')

    const actionField = this.form.querySelector('[name="action"]')
    let action = this.form.getAttribute('action')

    if (actionField) {
      action = actionField.value
    }

    // For ajax requests, the form component needs to be able to update
    // the form inputs associated with the form. This finds those input components
    this.inputComponents = this.pageComponents.findNestedComponents(this.form, 'input')


    return this.getSessionInfo()
      .then((sessionInfo) => {
        // Update the CSRF token with an updated one from the session info
        formData.set(sessionInfo.csrfTokenName, sessionInfo.csrfTokenValue)

        const request = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: formData,
        }

        const requestHandler = this.getRequestHandler()

        return requestHandler(request).then((request) => {
          return fetch(action, request).then((response) => {
            return response.json()
          }).then((response) => {
            return this.handleResponse(response)
          })
        })
      })
      .catch(() => {
      })
  }

  handleResponse(response) {

    this.toggleLoadingState(false);

    // Clear out any existing error messages
    (this.inputComponents || []).forEach((component) => {
      component.clearErrors();
    })

    const responseHandler = this.getResponseHandler()

    return responseHandler.call(this, response)
      .then((response) => {
        // Set the overall form state. Used to show the error/success state
        if (!response.success) {
          this.handleErrorResponse(response)
          return;
        }

        this.handleSuccessResponse(response)
      })
      .catch((result) => {
        console.warn(result)
      })
  }

  handleErrorResponse(response) {

    if (!this.formMessages) {
      return;
    }

    this.el.classList.add('form-error')
    this.el.classList.remove('form-success')

    const errorMessage = this.formMessages.querySelector('.error')
    if (!errorMessage) {
      return;
    }

    let message = response.message
    if (response.exception) {
      message = 'An error occurred during your submission.';
    }

    errorMessage.innerHTML = message

    // Update the error state for each field/input component that reported an error
    Object.keys(response.errors || {}).forEach((fieldName) => {
      const inputComponent = (this.inputComponents || []).find((component) => {
        return component.input.name == fieldName
      })

      if (!inputComponent) {
        return;
      }

      inputComponent.setErrors(response.errors[fieldName])
    })
  }

  handleSuccessResponse(response) {
    if (response.redirect) {
      let redirect = response.redirect;

      // Ensure redirect is a string before using matches
      if (typeof redirect === 'string' && !redirect.match(/^http/)) {
        redirect = `/${redirect.replace(/^\/?/, '')}`;
      }

      window.location.href = redirect;
      return;
    }

    this.el.classList.remove('form-error')
    this.el.classList.add('form-success')

    if (!this.formMessages) {
      return;
    }

    const successMessage = this.formMessages.querySelector('.success')
    if (!successMessage) {
      return;
    }

    // Use success state on the page
    // successMessage.innerHTML = response.message
  }

  toggleLoadingState(show) {

    if (show) {

      this.submitBtn.disabled = true;

      this.submitBtnCopy = this.submitBtn.querySelector('.btn-copy')
      if (this.submitCopy) {
        this.submitBtnCopy.innerHTML = 'Processing...';
      }

      return;
    }

    this.submitBtn.disabled = false;

    if (this.submitCopy && this.submitBtn.dataset.label) {
      this.submitBtnCopy.innerHTML = this.submitBtn.dataset.label;
    }
  }

  // Helper for fetching a CSRF token:
  getSessionInfo() {
    return fetch('/actions/users/session-info', {
      headers: {
        'Accept': 'application/json',
      },
    })
    .then(response => response.json());
  }

  reset() {
    this.el.classList.remove('form-error');
    this.el.classList.remove('form-success');

    (this.inputComponents || []).forEach((inputComponent) => {
      inputComponent.reset()
    })
  }

  setResponseHandler(responseHandler) {
    this.responseHandler = responseHandler
  }

  getResponseHandler() {

    if (typeof this.responseHandler === 'function') {
      return this.responseHandler;
    }

    return (response) => { return Promise.resolve(response) };
  }

  setRequestHandler(requestHandler) {
    this.requestHandler = requestHandler
  }

  getRequestHandler() {
    if (typeof this.requestHandler === 'function') {
      return this.requestHandler;
    }

    return (request) => { return Promise.resolve(request) };
  }
}
