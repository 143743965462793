export default {
  modules: [],
  speed: 500,
  spaceBetween: 11,
  slidesOffsetBefore: 18,
  slidesOffsetAfter: 18,
  loop: true,
  slidesPerView: 'auto',
  breakpoints: {
  }
}
