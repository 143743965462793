import Swiper from "swiper"
import swiperSettings from "./swiper-settings"
import domContentLoaded from '../events/dom-content-loaded';

class ProductVariationsCarousel {


  constructor(container) {
    this.container = container

    this.leftBtn = container.querySelector('[data-product-variations-carousel-left]')
    this.rightBtn = container.querySelector('[data-product-variations-carousel-right]')

    console.log(container)

    this.swiper = new Swiper(container.querySelector('.swiper'), {
      ...swiperSettings,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      breakpoints: {
      }
    })

    this.leftBtn.addEventListener('click', () => {
      this.swiper.slidePrev()
    })

    this.rightBtn.addEventListener('click', () => {
      this.swiper.slideNext()
    })

  }

}

export default function() {

  const init = function() {
    const sections = [...document.querySelectorAll('.product-variations-carousel')]

    if (sections.length === 0) {
      return
    }

    sections.forEach((section) => {
      new ProductVariationsCarousel(section)
    })
  }

  domContentLoaded().then(() => {
    init()
  })
}
