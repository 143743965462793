import TransitionModal from "../transition";

class ProjectCalculatorSendModal extends TransitionModal {

  constructor(modal) {
    super(modal)

    this.disclaimerElem = this.modal.querySelector('[data-displaimer]');

    this.handleFormResponse = this.handleFormResponse.bind(this)
  }

  open() {
    return this.updateContent().then(() => {
      return super.open();
    })
  }

  close() {
    return super.close().then(() => {
      if (this.formComponent) {
        this.formComponent.reset();
      }

      this.disclaimerElem.classList.remove('hidden')
      this.heading.classList.remove('hidden')
    })
  }

  updateContent() {
    this.formComponent = this.pageComponents.getComponentByType(this.modal, 'form')
    this.formComponent.setResponseHandler(this.handleFormResponse)
    this.formComponent.setRequestHandler(this.handleFormRequest)

    const configTag = document.getElementById('project-calculator-config');
    this.config = JSON.parse(configTag.innerHTML);

    return Promise.resolve()
  }

  handleFormResponse(response) {

    // Override the message for errors since the email field is the only field
    if (!response.success) {
      response.message = 'Submission error! Please confirm your email address and try again.';
      if (typeof response.errors['email'] !== 'undefined') {
        response.message = response.errors.email.join(', ')
      }
    }

    if (response.success) {
      this.disclaimerElem.classList.add('hidden')
      this.heading.classList.add('hidden')
    }

    return Promise.resolve(response)
  }

  handleFormRequest(request) {

    const formData = request.body

    // Merge the project calcultor results to the request
    try {
      const calculatorResults = JSON.parse(sessionStorage.getItem('projectCalculatorResults'));

      Object.keys(calculatorResults).forEach((resultKey) => {
        formData.append(resultKey, calculatorResults[resultKey])
      })

    } catch(e) {
      console.warn(e)
    }

    return Promise.resolve(request)
  }
}

export default ProjectCalculatorSendModal;
