import Swiper from "swiper";
import swiperSettings from './swiper-settings';

export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    let swiperContainer = document.querySelector('.blog-swiper')

    if(!swiperContainer){
      return;
    }

    function blogSwiper() {

      const swiper = new Swiper(swiperContainer, {
        ...swiperSettings,
        breakpoints: {
          768: {
            spaceBetween: 0,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            enabled: false,
          },
        }
      })
      swiper.init()
    }

    blogSwiper()
  })
}
