import Modal from "../modal"
import PricingBarGraph from "../../../graphs/pricing-bar-graph"
import { initModalTriggers } from ".."

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const defaultStateList = ['intro', 'project_type', 'location', 'calculating_results', 'results', 'whats_next'];
const defaultProjectStateList = ['location', 'calculating_results', 'results', 'whats_next'];

class ProjectCalculatorModal extends Modal {

  constructor(modal) {
    super(modal)

    this.animateOutComplete = this.animateOutComplete.bind(this)
    this.buttonClicked = this.buttonClicked.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.onProjectTypeSelectChange = this.onProjectTypeSelectChange.bind(this)
    this.onResultsSelectChange = this.onResultsSelectChange.bind(this)
    this.handleStartOverClicked = this.handleStartOverClicked.bind(this)

    this.bar = this.modal.querySelector('.modal-project-calculator__bar span.progress')
    this.isAnimating = false;
    this.installers = this.modal.querySelector('.modal-project-calculator__installers')
    this.results = this.modal.querySelector('.modal-project-calculator__results__text')
    this.summary = this.modal.querySelector('.modal-project-calculator__results__summary')
    this.chartContainer = this.modal.querySelector('.modal-project-calculator__chart')
    this.states = [...this.modal.querySelectorAll('[data-state]')]
    this.whatsNextMessaging = this.modal.querySelector('[data-whats-next-messaging]')
    this.progressSteps = this.states.filter((stateElem) => stateElem.hasAttribute('data-progress-step'))
    this.startOverBtns = [...this.modal.querySelectorAll('[data-calculator-start-over]')]

    this.state = 'intro';
    this.stateElem = this.getStateFromKey(this.state)
    this.pendingState = null;
    this.projectConfig = {}
    this.fullStateList = [...defaultStateList]
    this.projectStateList = []
    this.progressStateList = []

    this.nextBtns = []
    this.states.forEach((item) => {
      const nextBtn = item.querySelector('[data-action="next"]')
      if (nextBtn) {
        this.nextBtns.push(nextBtn)
      } else {
        this.nextBtns.push(null)
      }
    })

    this.inputs = [...this.modal.querySelectorAll('input')]
    this.inputs.forEach((input) => {
      input.addEventListener('change', this.inputChange)
    })

    this.setupStates()

    const buttons = [...this.modal.querySelectorAll('[data-action]')]
    buttons.forEach((item) => {
      item.addEventListener('click', this.buttonClicked)
    })

    this.startOverBtns.forEach((item) => {
      item.addEventListener('click', this.handleStartOverClicked)
    })

    this.pricingGraph = new PricingBarGraph(this.chartContainer)

    this.restart()
  }

  getStateFromKey(stateKey) {
    return this.states.find((stateElem) => stateElem.dataset.state === stateKey)
  }

  getStateIndexFromKey(stateKey) {
    return this.fullStateList.findIndex((key) => key === stateKey)
  }

  setupStates() {

    this.projectTypeProductSelect = this.modal.querySelector('[data-state="project_type"] select[name="product_type"]')
    this.projectTypeProductSelect.addEventListener('change', this.onProjectTypeSelectChange)

    // Setup results product selection
    this.resultsProductTypeSelect = this.modal.querySelector('[data-state="results"] select[name="product_type"]')
    this.resultsProductTypeSelect.addEventListener('change', this.onResultsSelectChange)

    // Results quantity select
    this.selectQuantity = this.modal.querySelector('[data-state="results"] select[name="product_quantity"]')
    this.selectQuantity.addEventListener('change', this.onResultsSelectChange)

    this.inputZip = this.modal.querySelector('.modal-project-calculator__input-zipcode input')


    this.states.forEach((stateElem) => {
      const setupHandlerName = stateElem.dataset.setupHandler

      if (!setupHandlerName) {
        return;
      }

      if (typeof this[setupHandlerName] !== 'function') {
        console.warn('State handler not found ', setupHandlerName)
        return;
      }

      this[setupHandlerName].call(this, stateElem)
    })
  }

  init(trigger) {
    return super.init(trigger).then(() => {
      return this.setupContent()
    })
  }

  setupContent() {
    const configTag = document.getElementById('project-calculator-config');
    this.config = JSON.parse(configTag.innerHTML);

    this.initSelectedProductId = null;
    this.initSelectedProjectType = null;
    if (this.trigger && typeof this.trigger.dataset !== 'undefined') {
      this.initSelectedProductId = this.trigger.dataset.productId || null;
      this.initSelectedProjectType = this.trigger.dataset.projectType || null;
    }

    return Promise.resolve()
  }

  handleStartOverClicked(event) {
    event.preventDefault()
    this.restart()
  }

  buttonClicked(event) {
    event.preventDefault()
    if (this.isAnimating) {
      return
    }

    const button = event.currentTarget;
    const action = button.getAttribute('data-action')
    if (action === 'close') {
      this.close()
    } else if (action === 'next') {
      this.changeState(this.getNextState())
    } else if (action === 'previous') {
      if (this.state === 'results') {
        this.changeState('location')
      } else {
        this.changeState(this.getPreviousState())
      }
    } else if (action === 'restart') {
      this.restart()
    } else if (action === 'project_type') {
      this.changeState('project_type')
    }
  }

  getNextState() {
    return this.fullStateList[this.getStateIndexFromKey(this.state) + 1]
  }

  getPreviousState() {
    return this.fullStateList[this.getStateIndexFromKey(this.state) - 1]
  }

  changeState(newState) {
    this.isAnimating = true

    this.states.forEach((item) => {
      item.classList.add('animate-out')
    })

    this.pendingState = newState
    setTimeout(this.animateOutComplete, 500)

    let allStatePromises = [];
    const changePromise =  new Promise((resolve) => {
      this.resolveChangeState = resolve;
    })

    allStatePromises.push(changePromise);

    this.modal.classList.toggle('calculator-started', newState != 'intro')
    this.modal.classList.toggle('calculator-complete', newState == 'whats_next')

    if (newState == 'project_type') {
      allStatePromises.push(this.handleTriggerSelections());
    }

    return Promise.all(allStatePromises);
  }

  animateOutComplete() {

    this.state = this.pendingState;
    this.stateElem = this.getStateFromKey(this.state)
    this.pendingState = null;

    const progressStateList = this.progressStateList || [];

    if (this.stateElem && this.state !== 'calculating_results') {
      const totalProgressSteps = progressStateList.length;
      const stepCount = progressStateList.findIndex((stateKey) => stateKey === this.stateElem.dataset.state) + 1;

      let percent = Math.min(stepCount / totalProgressSteps, 1);
      if (isNaN(percent)) {
        percent = 0;
      }

      this.bar.style.width = (percent * 100) + '%';
    }

    const state = this.state
    this.states.forEach((item) => {
      item.classList.remove('animate-out')
      if (item == this.stateElem) {
        item.classList.remove('hidden')
        return;
      }

      item.classList.add('hidden')
    })

    if (this.resolveChangeState) {
      this.resolveChangeState()
    }

    this.isAnimating = false

    this.updateInterface()

    window.analytics.updateHandlers();

    if (this.isOpen) {
      window.analytics.trackEvent({
        eventCategory: `ProjCalc_View_${state.replace(/\-/, '_')}`,
        eventAction: 'ProjCalc_State_Change',
        eventLabel: 'ProjCalc_View',
      })
    }

    if (state === 'calculating_results') {
      this.findInstallers()
        .then(() => {

          window.analytics.trackEvent({
            eventCategory: 'ProjCalc_Form_Submit_Estimate',
            eventAction: 'Form_Submit',
            eventLabel: 'ProjCalc_Estimate',
          })

          return this.changeState('results')
        })
        .then(() => {
          return this.updateResults()
        })
    }
  }

  updateResults() {
    return this.calculateResults().then((calculatedResults) => this.updateChart(calculatedResults))
  }

  findInstallers() {
    const searchParams = new URLSearchParams({
      interfaceId: 18871,
      zipcode: this.inputZip.value,
      radius: 100,
    })

    return fetch(`/api/locations/find-by-zipcode?${searchParams}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    }).then((response) => {
      return response.json();
    })
    .then((response) => {
      return this.handleInstallerResults(response)
    })
    .catch(function (err) {
      console.warn('Error loading page', err);
    });

    return Promise.resolve({});
  }

  restart() {
    this.projectTypeProductSelect.selectedIndex = 0
    this.resultsProductTypeSelect.selectedIndex = 0
    this.selectQuantity.selectedIndex = 0
    this.inputs.forEach((input) => {
      input.checked = false
    })

    this.inputZip.value = ''
    this.updateProductTypeSelects()
    this.updateInterface()
    this.changeState('intro')

    this.projectConfig = {}
    this.fullStateList = defaultStateList
  }

  updateInterface() {

    // Update the next button state based on if selections have been made
    this.states.forEach((stateElem) => {
      const nextButton = stateElem.querySelector('[data-action="next"]')
      const hasInputs = stateElem.querySelector('input')
      if (nextButton) {
        nextButton.disabled = (!hasInputs || stateElem.querySelector('input:checked')) ? false : true
      }
    })

    // update update the project  next button
    const currentNextButton = this.stateElem.querySelector('[data-action="next"]')

    // Perform addition validation on the project type step
    if (this.state == 'project_type') {
      if (currentNextButton) {
        const selectedProjectType = this.stateElem.querySelector('input:checked');
        const selectedProduct = this.projectTypeProductSelect.options[this.projectTypeProductSelect.selectedIndex].value;
        currentNextButton.disabled = (selectedProjectType && selectedProduct) ? false : true
      }
    }

    // Perform additional validation on the location button
    if (this.state == 'location') {
      const zip = this.inputZip.value + ''
      const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)
      currentNextButton.disabled = isValidZip ? false : true
    }
  }

  updateProductTypeSelects() {

    // Clear options for the project type product select
    while (this.projectTypeProductSelect.options.length > 0) {
      this.projectTypeProductSelect.remove(0)
    }

    while (this.resultsProductTypeSelect.options.length > 0) {
      this.resultsProductTypeSelect.remove(0)
    }

    // Add a default option for the project type product select
    const option = document.createElement('option')
    option.value = ''
    option.innerHTML = 'Product Type'
    this.projectTypeProductSelect.appendChild(option)

    const productTypeSelects = [this.projectTypeProductSelect, this.resultsProductTypeSelect]

    const projectTypeState = this.getStateFromKey('project_type')
    const projectType = projectTypeState.querySelector('input:checked')
    if (projectType) {

      this.projectConfig = this.config.project_type[projectType.value];
      // Create the full list of steps for the selected project type

      this.fullStateList = [...defaultStateList]
      this.fullStateList.splice(2, 0, ...this.projectConfig.questions)

      this.projectStateList = [...defaultProjectStateList];
      this.projectStateList.splice(0, 0, ...this.projectConfig.questions)

      this.progressStateList = [...this.projectStateList];
      this.progressStateList.splice(this.projectStateList.findIndex((stateKey) => stateKey === 'calculating_results'), 1)

      this.updateStateCounts()

      // Create a product option for each product type select
      ;(this.config[projectType.value] || []).forEach((product) => {
        productTypeSelects.forEach((select) => {
          const option = document.createElement('option')
          option.value = product.id
          option.innerHTML = product.name
          select.appendChild(option)
        })
      })

      this.projectTypeProductSelect.selectedIndex = 0
      this.resultsProductTypeSelect.selectedIndex = -1
    }
  }

  updateStateCounts() {

    const totalSteps = this.progressStateList.length

    this.progressStateList.forEach((stateKey, i) => {
      const state = this.getStateFromKey(stateKey)

      const stepCountElem = state.querySelector('[data-project-calculator-step-count]')
      if (stepCountElem) {
        stepCountElem.innerHTML = i + 1;
      }

      const totalStepElem = state.querySelector('[data-project-calculator-total-steps]')
      if (totalStepElem) {
        totalStepElem.innerHTML = totalSteps;
      }
    })
  }

  getSessionInfo() {
    return fetch('/actions/users/session-info', {
      headers: {
        'Accept': 'application/json',
      },
    })
    .then(response => response.json());
  }

  calculateResults() {

    const projectTypeState = this.getStateFromKey('project_type');
    const projectType = projectTypeState.querySelector('input:checked').value;
    const selectedProductId = this.projectTypeProductSelect[this.projectTypeProductSelect.selectedIndex].value

    const locationState = this.getStateFromKey('location')

    let projectTypeParams = {}

    this.projectConfig.questions.forEach((questionKey) => {
      projectTypeParams[questionKey] = this.getStateFromKey(questionKey).querySelector('input:checked').value
    })

    const selectedParams = {
      project_type: projectType,
      product_id: selectedProductId,
      quantity: this.selectQuantity.options[this.selectQuantity.selectedIndex].value,
      zip_code: locationState.querySelector('input').value,
      ...projectTypeParams
    }

    return this.getSessionInfo()
      .then((sessionInfo) => {

        const body = { ...selectedParams, [sessionInfo.csrfTokenName]: sessionInfo.csrfTokenValue }

        return fetch('/api/project-calculator/calculate', {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify(body),
        }).then((response) => {
          return response.json();
        })
        .then((response) => {
          return this.handleCalculatorResults({ ...response, ...selectedParams })
        })
        .catch(function (err) {
          console.warn('Error loading page', err);
        });
      })
  }

  handleCalculatorResults(calculatedResults) {
    sessionStorage.setItem('projectCalculatorResults', JSON.stringify({
      ...calculatedResults,
      price_min: calculatedResults.projectRangeMin,
      price_max: calculatedResults.projectRangeMax
    }))

    const projectSummary = this.getProjectSummary(calculatedResults);

    const priceMin = '$' + numberWithCommas(calculatedResults.projectRangeMin)
    const priceMax = '$' + numberWithCommas(calculatedResults.projectRangeMax)

    this.summary.innerHTML = `
      <h3 class="heading-sm">Project Details</h3>
      ${projectSummary}
    `;

    this.results.innerHTML = `
      <h3 class="heading-sm">Project Price Range</h3>
      <h4>${priceMin} - ${priceMax}</h4>
      <p>Based on your project details, we estimate it will cost between ${priceMin} and ${priceMax}.</p>
    `;

    return Promise.resolve(calculatedResults)
  }

  getProjectSummary(calculatedResults) {

    let summary = ['<ul class="rem:mb-[25px] flex flex-col rem:gap-[8px] body-md">'];

    summary.push(`<li><span class="bold">Project Type:</span> ${this.config.project_type[calculatedResults.project_type].label}</li>`)

    const selectedProduct = this.config[calculatedResults.project_type].find((product) => product.id == calculatedResults.product_id);
    summary.push(`<li><span class="bold">Project Type:</span> ${selectedProduct.name}</li>`)

    summary = summary.concat(this.projectConfig.questions.map((questionKey) => {
      const answer = this.config[questionKey].answers[calculatedResults[questionKey]]
      return `<li><span class="bold">${this.config[questionKey].summary_label}:</span> ${answer.label}</li>`
    }))

    summary.push('</ul>')

    return summary.join("\n");
  }

  updateChart(calculatedResults) {
    this.pricingGraph.updateDimensions()
    this.pricingGraph.setBaseRange(calculatedResults.baseRangeMin, calculatedResults.baseRangeMax)
    this.pricingGraph.setSelectedRange(calculatedResults.graphRangeMin, calculatedResults.graphRangeMax)
    this.pricingGraph.init()
    this.pricingGraph.tween()
  }

  updateNextButton(slide) {
    this.nextBtns[slide - 1].disabled = (this.states[slide - 1].querySelector('input:checked')) ? false : true
  }

  inputChange(event) {
    if (this.state == 'project_type') {
      this.updateProductTypeSelects()
    }

    this.updateInterface()
  }

  onProjectTypeSelectChange() {
    // get the value from the project type dropdown
    const projectTypeVal = this.projectTypeProductSelect.options[this.projectTypeProductSelect.selectedIndex].value

    // transfer it to the results page dropdown
    this.resultsProductTypeSelect.selectedIndex = [...this.resultsProductTypeSelect.options].findIndex((option) => option.value == projectTypeVal)
    this.updateInterface()
  }

  onResultsSelectChange() {
    // get the value from the results page dropdown
    const projectTypeVal = this.resultsProductTypeSelect.options[this.resultsProductTypeSelect.selectedIndex].value
    // transfer it to the dropdown on the project type page
    this.projectTypeProductSelect.selectedIndex = [...this.projectTypeProductSelect.options].findIndex((option) => option.value == projectTypeVal)

    this.updateResults()
  }

  handleTriggerSelections() {
    // const projectTypeElem = this.getStateFromKey('project_type')

    // if (!this.initSelectedProductId || !this.initSelectedProjectType) {
    //   return;
    // }

    // console.log(`input[type="radio"][value="${this.initSelectedProjectType}"]`)
    // const projectTypeField = projectTypeElem.querySelector(`input[type="radio"][value="${this.initSelectedProjectType}"]`)
    // if (projectTypeField) {
    //   projectTypeField.checked = true;

    //   this.updateProductTypeSelects()

    //   const selectedProductOptionIndex = [...this.projectTypeProductSelect.options].findIndex((option) => option.value == this.initSelectedProductId)
    //   if (selectedProductOptionIndex > -1) {
    //     this.projectTypeProductSelect.selectedIndex = selectedProductOptionIndex;
    //     this.onProjectTypeSelectChange()
    //   }
    // }


    return Promise.resolve();
  }

  handleInstallerResults(response) {
    let locations = [];
    if (response.results && response.results.locations) {
      locations = response.results.locations || [];
    }

    // Use the pending state since we're technically in the middle of a transition here
    const state = this.getStateFromKey('whats_next')

    // Clear out the existing list of installers
    this.installers.innerHTML = '';

    if (locations.length === 0) {
      state.classList.add('no-results')
      return;
    }

    state.classList.remove('no-results')

    const installerTemplate = document.getElementById('project-calculator-installers')

    locations.slice(0, 3).forEach((location) => {

      const installerElem = installerTemplate.content.cloneNode(true)
      const installerNameElem = installerElem.querySelector('[data-installer-name]')
      const installerPhoneElem = installerElem.querySelector('[data-installer-phone]')

      installerNameElem.innerHTML = location.metalocator_data.name;

      // Render the start rating of the installer
      if (location.metalocator_data.review_average) {
        const ratingContainer = installerElem.querySelector('.rating')
        ratingContainer.classList.remove('hidden')

        const ratingSvg = installerElem.querySelector('.star-rating')
        if (ratingSvg) {
          ratingSvg.style.setProperty('--star-rating-width', `${location.metalocator_data.review_average / 5 * 100}%`)
        }
      }

      // Render the distance
      // Distance field is oddly capitalized. Just noting incase ML ever changes it.
      if (location.metalocator_data.Distance) {
        const distanceElem = installerElem.querySelector('[data-installer-distance]')
        distanceElem.parentNode.classList.remove('hidden')
        distanceElem.parentNode.classList.add('flex')
        distanceElem.innerHTML = `${parseFloat(location.metalocator_data.Distance).toFixed(1)} mi`
      }

      // Render the phone number
      if (location.metalocator_data.phone) {
        installerPhoneElem.parentNode.classList.remove('hidden')
        installerPhoneElem.parentNode.classList.add('flex')
        const phoneLink = document.createElement('a')
        phoneLink.setAttribute('href', `tel:${location.metalocator_data.phone}`)
        phoneLink.innerHTML = location.metalocator_data.phone
        installerPhoneElem.appendChild(phoneLink)
      }

      // Populate the installer id
      if (location.site_data.id) {
        [...installerElem.querySelectorAll('[data-installer-id]')].forEach((installerIdElem) => {
          installerIdElem.setAttribute('data-installer-id', location.site_data.id)
        })
      }

      if (location.site_data.idHash) {
        [...installerElem.querySelectorAll('[data-installer-id-hash]')].forEach((installerIdHashElem) => {
          installerIdHashElem.setAttribute('data-installer-id-hash', location.site_data.idHash)
        })
      }

      if (location.site_data.slug) {
        [...installerElem.querySelectorAll('[data-installer-slug]')].forEach((installerSlugElem) => {
          installerSlugElem.setAttribute('data-installer-slug', location.site_data.slug)
        })
      }

      if (location.metalocator_data.name) {
        [...installerElem.querySelectorAll('[data-installer-name]')].forEach((installerNameElem) => {
          installerNameElem.setAttribute('data-installer-name', location.metalocator_data.name)
        })
      }

      this.installers.appendChild(installerElem)
    })

    initModalTriggers();
  }
}

export default ProjectCalculatorModal
