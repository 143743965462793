

import initProductVariationsCarousel from "./product-variations-carousel";


export default function() {
  document.addEventListener('DOMContentLoaded', () => {
  let swiperContainer = document.querySelector('.design-advice-carousel')
  if(!swiperContainer){
    return;
  }
  initProductVariationsCarousel();

})
}

