import Swiper from 'swiper';
import swiperSettings from './swiper-settings';

export default class {
  constructor({
    id,
  }) {

    const featuredProducts = document.getElementById(id)
    if (!featuredProducts) {
      return;
    }

    const swiperContainer = featuredProducts.querySelector('.swiper')

    if (!swiperContainer) {
      return;
    }

    this.leftBtn = featuredProducts.querySelector('[data-carousel-left]')
    this.rightBtn = featuredProducts.querySelector('[data-carousel-right]')

    this.swiper = new Swiper(swiperContainer, {
      ...swiperSettings,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      breakpoints: {
        768: {
          spaceBetween: 15,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          enabled: false,
        },
      }
    })

    this.leftBtn.addEventListener('click', () => {
      this.swiper.slidePrev()
    })

    this.rightBtn.addEventListener('click', () => {
      this.swiper.slideNext()
    })

  }
}
