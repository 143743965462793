import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import Lottie from 'lottie-web';

gsap.registerPlugin(ScrollTrigger)

export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    const calculatorFeatures = [...document.querySelectorAll('[data-calculator-feature]')]

    if (!calculatorFeatures.length) {
      return;
    }

    calculatorFeatures.forEach((calculatorFeature) => {

      const animation = Lottie.loadAnimation({
        container: calculatorFeature.querySelector('[data-calculator-feature-animation]'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/animations/VELUX_Calculator-Revised.json',
      })

      ScrollTrigger.create({
        trigger: calculatorFeature,
        start: 'top bottom+=10%',
        end: 'bottom top',
        toggleActions: "play none none reset",

        onEnter: () => {
          animation.play(0)
        },

        onLeave: () => {
          animation.goToAndStop(0)
        },

        onEnterBack: () => {
          animation.play(0)
        },

        onLeaveBack: () => {
          animation.goToAndStop(0)
        }
      })
    })
  })
}
