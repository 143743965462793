/**
  Toggle a component "zoom" css class from an outside element
  Note: Target element must have a 'zoom' css class defined for this to do anyting.
*/

export default function () {

  const eles = document.querySelectorAll("[data-zoom]");

  function addZoom (e) {
    const ele = document.getElementById(this.dataset.zoom);
    if (ele) {
      ele.classList.add("zoom");
    }
  }

  function removeZoom (e) {
    const ele = document.getElementById(this.dataset.zoom);
    if (ele) {
      ele.classList.remove("zoom");
    }
  }

  if (eles.length > 0) {
    eles.forEach((ele) => {
      ele.addEventListener("mouseover", addZoom);
      ele.addEventListener("mouseout", removeZoom);
    })
  }
}
