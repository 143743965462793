import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import Swiper from "swiper";
import swiperSettings from './swiper-settings';

gsap.registerPlugin(ScrollTrigger)

export default function () {
  document.addEventListener('DOMContentLoaded', function () {

    let swiperContainer = document.querySelector('.visualizer-swiper');

    if(!swiperContainer) {
      return;
    }

    /**
     * Initial Scroll/Slide In
     */

    if (window.innerWidth > 768) {
      ScrollTrigger.create({
        trigger: swiperContainer,
        start: 'top bottom',
        onEnter: () => {
          const slides = Array.from(swiperContainer.querySelectorAll(".swiper-slide"));
          slides.reverse();
          let n = 0;
          slides.forEach((ele) => {
            setTimeout(() => { ele.classList.add("slidein"); }, n);
            n += 250;
          });
        }
      })
    }

    /**
     *  Swiper Carousel
     */
    function doSwiperTrans (swiper) {
      const ele = document.querySelector('div[steps]');
      const active = swiper.wrapperEl.querySelectorAll(".swiper-slide-active");
      if (ele && active) {
        ele.addEventListener("transitionend", (e) => {
          ele.innerHTML = `
            <h4 class="heading-sm rem:pt-[16px]">${active[0].dataset.title}</h4>
            <p class="rem:pt-[10px] opacity-[.5]">${active[0].dataset.txt}</p>
          `;
          ele.classList.remove("fadeout");
        });
        ele.classList.add("fadeout");
      }
    }

    function doSwiper() {
      const swiper = new Swiper(swiperContainer, {
        ...swiperSettings,
        spaceBetween: 5,
        slidesOffsetBefore: 160,
        slidesOffsetAfter: 0,
        initialSlide: 0,
        breakpoints: {
          480: {
            slidesOffsetBefore: 200,
            spaceBetween: 5,
          },
          640: {
            slidesOffsetBefore: 240,
            spaceBetween: 5,
          },
          768: {
            spaceBetween: 0,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            enabled: false,
          }
        },
        on: {
          slideChangeTransitionStart: doSwiperTrans,
          afterInit:doSwiperTrans
        }
      })
      swiper.init()
    }
    doSwiper()
  })
}
