import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.config({ force3D: true })

export function runMotionGroup(group, disableScroll) {

  let scrollTrigger = {};
  if (disableScroll !== true && group.hasAttribute('data-motion-autoplay')) {
    scrollTrigger = {
      trigger: group,
      start: 'top bottom-=10%'
    };
  }

  if (disableScroll === true) {
    scrollTrigger = null;
  }

  gsap.fromTo(group.querySelectorAll('[data-motion-item]'),
    { opacity: 0, y: '25px' },
    {
      scrollTrigger: scrollTrigger,
      stagger: .15,
      opacity: 1,
      y: 0,
      duration: 2,
      ease: "power3.out",
      onComplete: function() {
        [...group.querySelectorAll('[data-motion-item]')].forEach((groupItem) => {
          groupItem.classList.add('motion-item-complete')
        })
      },
      onCompleteParams: [group]
    }
  )
}

export default function() {
  const groups = [...document.querySelectorAll('[data-motion-group][data-motion-autoplay]')]
  groups.forEach((group) => {
    runMotionGroup(group)
  })
}
