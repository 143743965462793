export default function() {

  const heroContent = document.querySelector('[data-intro-content]')
  const heroDrawer = document.querySelector('.hero-drawer')
  const preFooter = document.querySelector('.pre-footer')

  if (!heroContent || !heroDrawer) {
    return
  }

  function inViewport(entries) {
    const heroEntry = entries[0]
    const preFooterEntry = entries[1]
    if (heroEntry && heroEntry.isIntersecting) {
      heroDrawer.classList.remove('show')
    } else {
      heroDrawer.classList.add('show')
    }

    if (preFooterEntry && preFooterEntry.isIntersecting) {
      heroDrawer.classList.remove('show')
    }
  }

  const inViewportObserver = new IntersectionObserver(
    inViewport,
    {
      root: null,
      threshold: 0.0,
      // rootMargin: '10px 0px 1000px 0px',
    }
  )
  inViewportObserver.observe(heroContent)
  inViewportObserver.observe(preFooter)

}
