import Swiper from "swiper";
import swiperSettings from "./swiper-settings";

export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    let swiperInstances = {}

    let grids = [...document.querySelectorAll('[data-offset-grid]')]
    let mql = window.matchMedia("(min-width: 980px");

    if (grids.length === 0) {
      return;
    }

    function updateCarousels() {
      grids.forEach((grid, index) => {

        if (!mql.matches) {
          // Create a swiper instance for mobile
          swiperInstances[index] = new Swiper(grid, {
            ...swiperSettings,
            breakpoints: {
            }
          })
          return;
        }

        if (swiperInstances[index]) {
          swiperInstances[index].destroy()
          swiperInstances[index] = undefined;
        }
      })
    }

    mql.addEventListener("change", () => {
      updateCarousels()
    });

    updateCarousels()
  })
}
