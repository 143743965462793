export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    const mq = window.matchMedia('(min-width: 768px)')
    const promoKey = 'closedHomepagePromoPopup';
    const promoPopup = document.querySelector('[data-promo-popup]')

    if (!promoPopup) {
      return;
    }

    function togglePromo() {

      if (!mq.matches || sessionStorage.getItem(promoKey)) {
        promoPopup.classList.remove('fixed')
        promoPopup.classList.add('hidden')
        return;
      }

      if (!sessionStorage.getItem(promoKey)) {
        promoPopup.classList.add('fixed')
        promoPopup.classList.remove('hidden')
      }
    }

    mq.addEventListener('change', () => {
      togglePromo()
    })

    if (mq.matches) {
      togglePromo()
    }

    togglePromo()

    const promoCloseBtn = promoPopup.querySelector('[data-promo-close]')
    promoCloseBtn.addEventListener('click', () => {
      sessionStorage.setItem(promoKey, true);

      togglePromo()
    })
  })
}
