
import './polyfills';

// Components
import Accordion from './components/accordion';
import FeaturesCarousel from './components/features-carousel';
import HeroCarousel from './components/hero-carousel';
// import ImageComparison from './components/image-comparison';
import ImageComparisonsCarousel from './components/image-comparisons-carousel';
import InspirationCarousel from './components/inspiration-carousel';
// import InstallerAdminInstallers from './components/installer-admin/installers';
// import InstallerAdminProductsForm from './components/installer-admin/products-form';
// import InstallerAdminProjectsForm from './components/installer-admin/projects-form';
import InstallerReviews from './components/installer-reviews';
import InstallerReviewsCarousel from './components/installer-reviews-carousel';
import GalleryGrid from '../components/gallery-grid';
import Main from './components/main';
import Modal from './components/modal';
// import ProductDetails from './components/product-details';
// import ProductDetailsModal from './components/product-details-modal';
import ProductsSlider from './components/products-slider';
import ProductTypesSlider from './components/product-types-slider';
import ProjectsSlider from './components/projects-slider';
import RoomCarousel from './components/room-carousel';
import RoomsSlider from './components/rooms-slider';
// import StatsChart from './components/stats-chart';
import VimeoVideo from './components/vimeo-video';

// Define map of component handles to component classes
/* eslint-disable quote-props */
const classMap = {
  'accordion': Accordion,
  'features-carousel': FeaturesCarousel,
  'hero-carousel': HeroCarousel,
  // 'image-comparison': ImageComparison,
  'image-comparisons-carousel': ImageComparisonsCarousel,
  'inspiration-carousel': InspirationCarousel,
  // 'installer-admin-products-form': InstallerAdminProductsForm,
  'installer-reviews': InstallerReviews,
  'installer-reviews-carousel': InstallerReviewsCarousel,
  'main': Main,
  'modal': Modal,
  // 'product-details': ProductDetails,
  // 'product-details-modal': ProductDetailsModal,
  'products-slider': ProductsSlider,
  'product-types-slider': ProductTypesSlider,
  'projects-slider': ProjectsSlider,
  'room-carousel': RoomCarousel,
  'rooms-slider': RoomsSlider,
  // 'stats-chart': StatsChart,
  'vimeo-video': VimeoVideo,
  'gallery-grid': GalleryGrid,
};

export default classMap;
