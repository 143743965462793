import { initOptionToggles } from '../option-toggle'
import { initTransformations } from '../transformation'
import Modal from './modal'

class TrayModal extends Modal {

  constructor(modal) {
    super(modal)
    this.modelContent = this.modal.querySelector('[data-modal-content]')

    this.handleCloseTransition = this.handleCloseTransition.bind(this)
  }

  open() {
    super.open()
    setTimeout(() => {
      this.modal.classList.add('reveal')
    })
  }

  close() {
    this.modal.classList.remove('reveal')
    this.modal.addEventListener('transitionend', this.handleCloseTransition)
  }

  init(trigger) {
    return super.init(trigger).then(() => {
      return this.setupContent()
    })
  }

  setupContent() {
    this.modelContent.innerHTML = document.getElementById(this.trigger.dataset.modalContent).innerHTML

    initOptionToggles();
    initTransformations();

    return Promise.resolve()
  }

  resetContent() {
    this.modelContent.innerHTML = ''
  }

  handleCloseTransition(event) {
    if (event.target == this.modal) {
      this.modal.removeEventListener('transitionend', this.handleCloseTransition)
      this.resetContent()
      super.close()
    }
  }
}

export default TrayModal
