import { Tabs } from "./tabs";
import { runMotionGroup } from "./site-motion";

export default class {
  constructor({
    id,
  }) {

    this.el = document.getElementById(id)
    this.tabSelect = this.el.querySelector('.tab-select select')
    this.tabs = new Tabs(this.el, this.el.querySelector('[role="tablist"]'));

    this.handleTabChanged = this.handleTabChanged.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)

    this.el.addEventListener('tab_changed', this.handleTabChanged)
    this.tabSelect.addEventListener('change', this.handleSelectChange);
  }

  handleTabChanged(event) {
    const activeTab = event.detail.activeTab
    const panelId = activeTab.getAttribute('aria-controls');

    const tabPanel = document.getElementById(panelId)
    if (tabPanel) {
      runMotionGroup(tabPanel)
    }
  }

  handleSelectChange(event) {
    const tabElement = document.getElementById(this.tabSelect.options[this.tabSelect.selectedIndex].value)
    this.tabs.setSelectedTab(tabElement)
  }
}
