export default class {
  constructor({
    id,
    projectLimit,
    addHandle,
    removeHandle,
    projects
  }) {

    this.el = document.getElementById(id);
    this.addHandle = addHandle;
    this.removeHandle = removeHandle;
    this.addButton = this.el.querySelector(this.addHandle)
    this.limit = projectLimit;

    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)

    this.projects = projects
    this.projectTemplate = document.getElementById('project-tmpl')
    this.projectsContainer = document.getElementById('projects')

    this.init()

    // Add event listeners
    this.addButton.addEventListener('click', this.handleAddClick);
    this.el.addEventListener('click', this.handleRemoveClick)
  }

  init() {
    console.log(this.projects)
    this.projects.forEach((project, i) => {
      const slide = this.renderSlide({ project }, i)
      this.projectsContainer.appendChild(slide)
    })
  }

  renderSlide(data, index) {

    const count = index + 1;

    const tmpl = this.projectTemplate.content.cloneNode(true);
    const fieldset = tmpl.querySelector('fieldset');

    ;[...fieldset.querySelectorAll('input,textarea')].forEach((input) => {
      // Update the name of the field to replace placeholder values
      const name = input.getAttribute('name')
      input.setAttribute('name', name.replace(':id', data.project.id))

      // Update the description
      if (name.includes('projectDescription')) {
        input.value = data.project.description
      }

      // Update the value for the sort order
      if (name.includes('sortOrder')) {
        input.value = data.project.id
      }

      // Update the image value
      if (name.includes('projectImage') && input.type != 'file' && data.project.image) {
        input.value = data.project.image.id
      }
    })

    // Replace instnaces of the project count
    fieldset.querySelectorAll('[data-project-count]').forEach((countElem) => {
      countElem.textContent = `${count}`
    })

    const removeButton = fieldset.querySelector(this.removeHandle)
    removeButton.setAttribute('data-project-id', data.project.id)

    // Populate the image if one is set
    const imgContainer = fieldset.querySelector('.project-image')
    let img = '';
    if (data.project.image) {
      img = `<img src="${ data.project.image.url }">`
    }

    imgContainer.innerHTML = img;

    return fieldset
  }

  // Event handler functions
  handleAddClick(e) {
    e.preventDefault();

    const newProjects = this.projects.filter((project) => `${project.id}`.includes('new'))

    const newProject = {
      id: `new:${!newProjects.length ? 1 : newProjects.length + 1}`,
      description: '',
      image: null,
    }

    this.projects.push(newProject)

    this.projectsContainer.appendChild(this.renderSlide({ project: newProject }, this.projects.length - 1))

    if (this.projects.length >= this.limit) {
      this.addButton.style.display = 'none';
    }
  }

  handleRemoveClick(e) {

    const removeButton = e.target.matches(this.removeHandle) && e.target || e.target.closest(this.removeHandle)
    if (!removeButton) {
      return;
    }

    e.preventDefault();

    // Remove the project from the list
    const projectIndex = this.projects.findIndex((project) => project.id == removeButton.dataset.projectId)
    this.removeProjectByIndex(projectIndex)
  }

  removeProjectByIndex(index) {
    this.projects.splice(index, 1)

    // Remove the project from the dom
    const fieldsets = [...this.el.querySelectorAll('fieldset')]
    fieldsets[index].remove()

    this.reindexProjects()
  }

  // Update the count elements to match their current position in the project list
  reindexProjects() {
    ;[...this.el.querySelectorAll('fieldset')].forEach((fieldset, index) => {
      fieldset.querySelectorAll('[data-project-count]').forEach((countElem) => {
        countElem.textContent = `${index + 1}`
      })
    })
  }
}
