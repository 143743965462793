import Swiper from "swiper";
import swiperSettings from "./swiper-settings";

export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    const featuredProjects = [...document.querySelectorAll('.featured-project')]

    if (featuredProjects.length === 0) {
      return;
    }

    featuredProjects.forEach((featuredProject) => {
      const swiper = new Swiper(featuredProject.querySelector('.swiper'), {
        ...swiperSettings,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        breakpoints: {
        }
      });

      const leftBtn = featuredProject.querySelector('[data-featured-project-left]')
      const rightBtn = featuredProject.querySelector('[data-featured-project-right]')

      if (leftBtn && rightBtn) {
        leftBtn.addEventListener('click', () => {
          swiper.slidePrev()
        })

        rightBtn.addEventListener('click', () => {
          swiper.slideNext()
        })
      }
    })
  })
}
