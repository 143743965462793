
class Tabs {

  constructor(groupNode, tablist) {
    this.tablistNode = groupNode

    this.tabs = []

    this.firstTab = null
    this.lastTab = null

    this.tabs = [];
    if (tablist) {
      this.tabs = [...tablist.querySelectorAll('[role=tab]')]
    } else {
      this.tabs = [...this.tablistNode.querySelectorAll('[role=tab]')]
    }

    this.tabpanels = []

    for (var i=0; i<this.tabs.length; i+=1) {
      var tab = this.tabs[i]
      var tabpanel = document.getElementById(tab.getAttribute('aria-controls'))

      tab.tabIndex = -1
      tab.setAttribute('aria-selected', 'false')
      this.tabpanels.push(tabpanel)

      tab.addEventListener('keydown', this.onKeydown.bind(this))
      tab.addEventListener('click', this.onClick.bind(this))

      if (!this.firstTab) {
        this.firstTab = tab
      }
      this.lastTab = tab
    }

    this.setSelectedTab(this.firstTab, false)
  }

  setSelectedTab(currentTab, setFocus) {
    if (typeof setFocus !== 'boolean') {
      setFocus = true
    }
    for (var i = 0; i < this.tabs.length; i += 1) {
      var tab = this.tabs[i]
      if (currentTab === tab) {
        tab.setAttribute('aria-selected', 'true')
        tab.removeAttribute('tabindex')
        this.tabpanels[i].classList.remove('hidden')
        if (setFocus) {
          tab.focus()
        }
      } else {
        tab.setAttribute('aria-selected', 'false')
        tab.tabIndex = -1
        this.tabpanels[i].classList.add('hidden')
      }
    }

    this.tablistNode.dispatchEvent(new CustomEvent('tab_changed', { detail: { activeTab:  currentTab, tabs: this } }))
  }

  setSelectedToPreviousTab(currentTab) {
    var index
    if (currentTab === this.firstTab) {
      this.setSelectedTab(this.lastTab)
    } else {
      index = this.tabs.indexOf(currentTab)
      this.setSelectedTab(this.tabs[index - 1])
    }
  }

  setSelectedToNextTab(currentTab) {
    var index
    if (currentTab === this.lastTab) {
      this.setSelectedTab(this.firstTab)
    } else {
      index = this.tabs.indexOf(currentTab)
      this.setSelectedTab(this.tabs[index + 1])
    }
  }

  // Event Handlers
  onKeydown(event) {
    const target = event.currentTarget
    let flag = false

    switch (event.key) {
      case 'ArrowLeft':
        this.setSelectedToPreviousTab(target)
        flag = true
        break
      case 'ArrowRight':
        this.setSelectedToNextTab(target)
        flag = true
        break
      case 'Home':
        this.setSelectedTab(this.firstTab)
        flag = true
        break
      case 'End':
        this.setSelectedTab(this.lastTab)
        flag = true
        break
      default:
        break
    }
    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }
  onClick(event) {
    this.setSelectedTab(event.currentTarget)
  }

}

function initTabs () {
  document.addEventListener('DOMContentLoaded', () => {
    const items = document.querySelectorAll('.tabs [role=tablist]')
    if (items.length > 0) {
      items.forEach((item) => {
        new Tabs(item);
      })
    }
  })
}

export {Tabs, initTabs};
