export default function() {

  const header = document.querySelector('[data-intro-header]')
  const introContent = document.querySelector('[data-intro-content]')

  if (!header || !introContent) {
    return;
  }

  function updateHero(disableTransition) {
    const introContentRect = introContent.getBoundingClientRect()

    // Allow disabling the transition on resize or if the user already scrolled
    if (disableTransition || window.scrollY > 0) {
      header.style.transition = 'none';
    }

    header.style.setProperty('--intro-header-height', `${(window.innerHeight - introContentRect.height) / window.innerHeight * 100}dvh`)
  }

  window.addEventListener('resize', () => {
    updateHero(true)
  })

  document.addEventListener('DOMContentLoaded', () => {
    updateHero()
  })
}
