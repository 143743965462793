import { router, scroll } from 'ui-utilities';

export default class {
    constructor({
        id,
        lockedClass,
        getLatLng = false,
        state,
        actions,
        events,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const links = el.querySelectorAll('a');
        const anchors = Array.from(links).filter(link => link.hasAttribute('data-anchor'));
        const videos = Array.from(links).filter(link => link.href.includes('/videos/'));
        const hash = window.location.hash.replace('#', '');
        const hashEl = document.getElementById(hash);

        // Global state
        state.headerHeight = 0;//document.querySelector('.header').offsetHeight;

        // Event handler functions
        function handleTouchMove(e) {
            e.preventDefault();
        }
        function handleLockScroll() {
            document.body.classList.add(lockedClass);
            // Prevent scroll on iOS
            document.addEventListener('touchmove', handleTouchMove);
        }
        function handleUnlockScroll() {
            document.body.classList.remove(lockedClass);
            // Enable scroll on iOS
            document.removeEventListener('touchmove', handleTouchMove);
        }
        const handleAnchors = Array.from(anchors).map(anchor => e => {
            e.preventDefault();

            const h = anchor.href.split('#')[1];

            scroll.to(h, state.headerHeight);
        });
        const handleVideos = Array.from(videos).map(video => e => {
            e.preventDefault();

            const url = video.href;

            function cb(res) {
                const markup = res;

                events.emit(actions.loadModal, { markup });
            }

            router.get({ url, cb });

            // GTM
            let slug = url.split('/').pop();

            dataLayer.push({ // eslint-disable-line no-undef
                "event": "videoPlayer",
                "eventCategory": "Video",
                "eventAction": "Click to Play",
                "eventLabel": slug,
            });
        });

        // Add event listeners
        events.on(actions.lockScroll, handleLockScroll);
        events.on(actions.unlockScroll, handleUnlockScroll);
        Array.from(anchors).forEach((anchor, i) => {
            anchor.addEventListener('click', handleAnchors[i]);
        });
        Array.from(videos).forEach((video, i) => {
            video.addEventListener('click', handleVideos[i]);
        });

        // Get lat lng from HTML5 geolocation
        if (getLatLng && window.location.protocol.includes('s') && 'geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                const url = window.location.href;
                const latlng = `${coords.latitude},${coords.longitude}`;

                // Return if lat lng already set
                if (url.includes('zip=') || url.includes('latlng=')) return;

                window.location.href = `${url}${url.includes('?') ? '&' : '?'}latlng=${latlng}`;
            });
        }

        if (hashEl !== null) {
            // setTimeout to fix Chrome bug where the page would jump back to the top
            setTimeout(function() {
                window.scrollTo(0, window.pageYOffset - (state.headerHeight * 1.3));
            }, 0);
        }
    }
}
