export default class {
  constructor({
    id,
    withBackground,
    pageComponents,
  }) {
    this.el = document.getElementById(id)
    this.pageComponents = pageComponents

    if (!this.el) {
      return;
    }

    if (withBackground) {
      this.background = this.el.querySelector('.media')
    }

    this.handleFormResponse = this.handleFormResponse.bind(this)
  }

  handleComponentsCreated() {
    this.formComponent = this.pageComponents.getComponentByType(this.el, 'form')
    this.formComponent.setResponseHandler(this.handleFormResponse)
  }

  handleFormResponse(response) {

    if (this.background) {
      this.background.classList.add('solid-50')
    }

    // Override the message for errors since the email field is the only field
    if (!response.success) {
      response.message = 'Submission error! Please confirm your email address and try again.';
      if (typeof response.errors['email'] !== 'undefined') {
        response.message = response.errors.email.join(', ')
      }
    }

    if (response.success) {
      try {
        sessionStorage.setItem('newsletter_success', true)
      } catch(e) {
        console.warn(e)
      }
    }

    return Promise.resolve(response)
  }
}
