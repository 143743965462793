
export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    let eles = document.querySelectorAll('.btn-toggle')

    if (eles.length < 1) {
      return;
    }

    function toggle (e) {
      e.preventDefault();
      const active = this.classList.contains("active");
      const targetId = this.dataset.targetId;
      if (active) {
        this.classList.remove("active");
      } else {
        this.classList.add("active");
      }

      if (targetId) {
        const targetEle = document.getElementById(targetId);
        if (!targetEle) return;
        if (active) {
          targetEle.classList.remove("active");
        } else {
          targetEle.classList.add("active");
        }
      }
    }

    eles.forEach((ele) => {
      ele.addEventListener("click", toggle)
    });
  })
}
