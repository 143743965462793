/* eslint-disable */

class ColumnCountToggle  {

  constructor (ele) {
    this.ele = ele;

    console.log(this.ele)
    this.plus = this.ele.querySelector('.add');
    this.minus = this.ele.querySelector('.subtract');
    this.input = this.ele.querySelector('#cols')

    this.handlechange = this.handlechange.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    if (typeof this.input.dataset.colcounttog == "string") {
      this.min = parseInt(this.input.getAttribute('min'));
      this.max = parseInt(this.input.getAttribute('max'));
      this.val = parseInt(this.input.getAttribute('value'));

      this.input.addEventListener('input', this.handlechange.bind(this.input, this));
      this.setCols(this.getCols());
    }

    this.plus.addEventListener('click', this.zoomIn.bind(this.input, this));
    this.minus.addEventListener('click', this.zoomOut.bind(this.input, this));

    this.rangeInputUpdate()
  }

  zoomIn(e){

    let n = parseInt(this.input.value);
    if (n > this.min) {
      n--;
      this.input.value = n;
    };

    if(n == this.min){
      document.querySelector('.dynamic-cols').classList.add('two')
    } else {
      if(document.querySelector('.dynamic-cols').classList.contains('two')){
        document.querySelector('.dynamic-cols').classList.remove('two')
      }
    }

    document.documentElement.style.setProperty('--dc_column-count', n);
    this.rangeInputUpdate()
  }

  zoomOut(e){

    let n = parseInt(this.input.value);
    if (n < this.max){
      n++;
      this.input.value = n;
    }
    if(n == this.min){
      document.querySelector('.dynamic-cols').classList.add('two')
    } else {
      if(document.querySelector('.dynamic-cols').classList.contains('two')){
        document.querySelector('.dynamic-cols').classList.remove('two')
      }
    }

    document.documentElement.style.setProperty('--dc_column-count', n);
    this.rangeInputUpdate()
  }

  rangeInputUpdate() {
    const tempSliderValue = this.input.value;

    const progress = ((tempSliderValue - this.min) / (this.max - this.min)) * 100;
    this.input.style.background = `linear-gradient(to left, #ccc ${progress}%, currentColor ${progress}%)`;
  }

  getCols () {
    let n = parseInt(this.input.value);
    if (isNaN(n) || n > this.max || n < this.min) n = 3;
    if(n == this.min){
      document.querySelector('.dynamic-cols').classList.add('two')
    } else {
      if(document.querySelector('.dynamic-cols').classList.contains('two')){
        document.querySelector('.dynamic-cols').classList.remove('two')
      }
    }
    return n;
  }

  setCols (n) {
    document.documentElement.style.setProperty('--dc_column-count', n);
  }

  handlechange (o, e) {
    this.rangeInputUpdate()
    o.setCols(o.getCols());
  }
}

function initColumnCountToggle () {
  document.addEventListener('DOMContentLoaded', () => {
    // const eles = document.querySelectorAll('input[data-colcounttog]');
    const eles = document.querySelectorAll('.range-wrapper');
    if (eles.length > 0) {
      eles.forEach((ele) => {
        new ColumnCountToggle(ele);
      });
    }
  });
}

export { ColumnCountToggle, initColumnCountToggle };
