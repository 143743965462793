import ProjectRegion from './project-region';

export const initProjectRegions = function() {

  document.addEventListener('DOMContentLoaded', () => {

    const projectRegions = [...document.querySelectorAll('.project-regions')]

    if (projectRegions.length === 0) {
      return;
    }

    projectRegions.forEach((projectRegion) => {

      const regionContentBlocks = [...projectRegion.querySelectorAll('.project-regions__region-content')];
      const regionMediaBlocks = [...projectRegion.querySelectorAll('.project-regions__region-media')];

      const regions = {}
      let currentActive = null;

      regionContentBlocks.forEach((contentBlock, index) => {
        const region = new ProjectRegion(projectRegion, contentBlock, regionMediaBlocks[index])

        regions[contentBlock.dataset.regionId] = region;
        region.init()

        // Set the first region in the list to active
        if (index == 0) {
          region.show();
          currentActive = region;
        }
      })

      const regionSelector = projectRegion.querySelector('select')
      if (regionSelector) {
        regionSelector.addEventListener('change', (event) => {

          if (currentActive) {
            currentActive.hide()
          }

          const regionId = regionSelector.options[regionSelector.selectedIndex].value;
          regions[regionId].show(250);
          currentActive = regions[regionId];
        })
      }
    })
  })
}
