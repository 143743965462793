// scroll-m-page-top-offset

export default function() {
  document.addEventListener('DOMContentLoaded', () => {

    let eles = document.querySelectorAll('a[href^="#"]')

    if (eles.length < 1) {
      return;
    }

    function doScroll (e) {
      e.preventDefault();
      const hash = this.href.split('#')[1];
      if (!hash) return;

      const anchorByName = document.querySelector(`a[name=${hash}]`);
      const anchorById = document.getElementById(hash);

      const anchor = anchorByName || anchorById || null;

      if (!anchor) {
        return;
      }

      anchor.scrollIntoView({ behavior: "smooth" });
    }

    eles.forEach((ele) => {
      ele.addEventListener("click", doScroll)
    });
  })
}
