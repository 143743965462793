export default class {
  constructor({
    id,
    hotspotHandle,
    featureHandle,
    prevHandle,
    nextHandle,
    activeClass,
  }) {
    const el = document.getElementById(id);
    this.hotspots = [...el.querySelectorAll(hotspotHandle)];
    this.features = [...el.querySelectorAll(featureHandle)];
    this.prevButtons = [...el.querySelectorAll(prevHandle)];
    this.nextButtons = [...el.querySelectorAll(nextHandle)];

    this.activeClass = activeClass;

    this.handleHotspotClick = this.handleHotspotClick.bind(this)
    this.handleNextPrevClick = this.handleNextPrevClick.bind(this)

    // State variables
    this.activeIndex = null;

    this.hotspots.forEach((hotspot) => {
      hotspot.addEventListener('click', this.handleHotspotClick);
    });

    this.prevButtons.forEach((prevButton, i) => {
      prevButton.addEventListener('click', this.handleNextPrevClick)
      this.nextButtons[i].addEventListener('click', this.handleNextPrevClick)
    })
  }

  handleHotspotClick(event) {
    const target = event.currentTarget
    this.setActiveFeature(parseInt(target.dataset.index))
  }

  handleNextPrevClick(event) {
    const target = event.currentTarget
    const isNext = target.dataset.direction == 'next';
    const maxIndex = this.hotspots.length - 1;

    if (isNext) {
      let goToIndex = this.activeIndex + 1;
      if (goToIndex > maxIndex) {
        goToIndex = 0;
      }
      this.setActiveFeature(goToIndex);
      return;
    }

    let goToIndex = this.activeIndex - 1;
    if (goToIndex < 0) {
      goToIndex = maxIndex;
    }
    this.setActiveFeature(goToIndex);
    return;

  }

  setActiveFeature(index) {
    this.features.forEach((feature, featureIndex) => {
      feature.classList.toggle(this.activeClass, featureIndex === index)
    })

    this.hotspots.forEach((hotspot, hotspotIndex) => {
      hotspot.classList.toggle(this.activeClass, hotspotIndex === index)
    })

    this.activeIndex = index
  }

}
