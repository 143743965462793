import Swiper from 'swiper';
import swiperSettings from './swiper-settings';

export default class {
  constructor({
    id,
  }) {

    const featuredRooms = document.getElementById(id)

    if (!featuredRooms) {
      return;
    }

    const swiperContainer = featuredRooms.querySelector('.swiper')

    if (!swiperContainer) {
      return;
    }

    this.leftBtn = featuredRooms.querySelector('[data-carousel-left]')
    this.rightBtn = featuredRooms.querySelector('[data-carousel-right]')

    this.swiper = new Swiper(swiperContainer, {
      ...swiperSettings,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      breakpoints: {
      }
    })

    this.leftBtn.addEventListener('click', () => {
      this.swiper.slidePrev()
    })

    this.rightBtn.addEventListener('click', () => {
      this.swiper.slideNext()
    })

  }
}
